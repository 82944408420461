import {useState, useEffect} from 'react'
import {Select} from '@bakkt/components'
import {useSelector} from 'react-redux'
import {selectCurrentUser} from '../../app/reducer'
import PermissionManager from '../../app/PermissionManager'

const SwitchPartner = () => {
	const partners = PermissionManager.getAllPartnersObjCurrentUserCanAccess()
	const partnersArr: {id: string; name: string}[] = PermissionManager.getAllPartnersArrayCurrentUserCanAccess()
	const selectedPartnerId = localStorage.getItem('selectedPartnerId')
	const valueToShow = partners[selectedPartnerId]
	const [value, setValue] = useState(valueToShow)

	useEffect(() => {
		if (selectedPartnerId) {
			setValue(partners[selectedPartnerId])
		}
	}, [selectedPartnerId, value])

	return (
		<Select
			shadowContainerStyle={{display: 'flex', marginRight: 20}}
			options={partnersArr}
			label='Switch Partners'
			disableClearable={false}
			value={value}
			optionFunction={item => item.name}
			labelFunction={item => item.name}
			onChange={(e, value) => {
				if (value) {
					const {id, name} = value
					localStorage.setItem('selectedPartnerId', id)
					window.history.replaceState(null, null, '?selectedPartnerId=' + id)
					window.location.reload()
				}
			}}
		/>
	)
}

export default SwitchPartner
