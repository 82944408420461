/* tslint:disable */
/* eslint-disable */
/**
 * Bakkt Merchant Portal Service APIs
 * Bakkt Merchant Portal Service APIs
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Amount
 */
export interface Amount {
    /**
     * 
     * @type {string}
     * @memberof Amount
     */
    'currency'?: AmountCurrencyEnum;
    /**
     * 
     * @type {number}
     * @memberof Amount
     */
    'amount'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AmountCurrencyEnum {
    USD = 'USD',
    AAVE = 'AAVE',
    AVAX = 'AVAX',
    AXS = 'AXS',
    BAT = 'BAT',
    BCH = 'BCH',
    BTC = 'BTC',
    DOGE = 'DOGE',
    ETH = 'ETH',
    LINK = 'LINK',
    LTC = 'LTC',
    MANA = 'MANA',
    MATIC = 'MATIC',
    SOL = 'SOL',
    UNI = 'UNI',
    USDC = 'USDC',
    LXX = 'LXX',
    LCP = 'LCP',
    LWR = 'LWR',
    LMO = 'LMO',
    LKO = 'LKO',
    LED = 'LED'
}

/**
 * 
 * @export
 * @interface ApiAttachment
 */
export interface ApiAttachment {
    /**
     * 
     * @type {string}
     * @memberof ApiAttachment
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAttachment
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAttachment
     */
    'attachmentName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAttachment
     */
    'attachmentRef'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAttachment
     */
    'tokenRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAttachment
     */
    'contentUrl'?: string;
}
/**
 * 
 * @export
 * @interface ApiAttachmentRequest
 */
export interface ApiAttachmentRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiAttachmentRequest
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAttachmentRequest
     */
    'attachmentRef'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAttachmentRequest
     */
    'attachmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAttachmentRequest
     */
    'tokenRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAttachmentRequest
     */
    'contentUrl'?: string;
}
/**
 * 
 * @export
 * @interface ApiChildTransactionDetails
 */
export interface ApiChildTransactionDetails {
    /**
     * 
     * @type {string}
     * @memberof ApiChildTransactionDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiChildTransactionDetails
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiChildTransactionDetails
     */
    'transactionType'?: ApiChildTransactionDetailsTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiChildTransactionDetails
     */
    'transactionStatus'?: ApiChildTransactionDetailsTransactionStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiChildTransactionDetails
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiChildTransactionDetails
     */
    'fees'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiChildTransactionDetails
     */
    'agent'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiChildTransactionDetails
     */
    'notes'?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiChildTransactionDetails
     */
    'adjustmentId'?: object;
    /**
     * 
     * @type {object}
     * @memberof ApiChildTransactionDetails
     */
    'achFileId'?: object;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiChildTransactionDetailsTransactionTypeEnum {
    CRYPTO_CURRENCY = 'CRYPTO_CURRENCY',
    REWARD = 'REWARD',
    REDEMPTION_CANCEL = 'REDEMPTION_CANCEL',
    REDEMPTION = 'REDEMPTION',
    CREDIT = 'CREDIT',
    CREDIT_CANCEL = 'CREDIT_CANCEL',
    BUY = 'BUY',
    BUY_CANCEL = 'BUY_CANCEL',
    PAYMENT = 'PAYMENT',
    REFUND = 'REFUND',
    VOID = 'VOID',
    CHARGEBACK = 'CHARGEBACK',
    LOYALTY = 'LOYALTY',
    PAYOUT = 'PAYOUT',
    PAY_WITH_POINTS = 'PAY_WITH_POINTS',
    LOYALTY_CRYPTO_REDEEM_FAILED = 'LOYALTY_CRYPTO_REDEEM_FAILED',
    CONVERT_CANCEL = 'CONVERT_CANCEL',
    LOYALTY_BUY_CANCEL = 'LOYALTY_BUY_CANCEL',
    LOYALTY_BUY = 'LOYALTY_BUY',
    LOYALTY_REDEEM = 'LOYALTY_REDEEM',
    INCENTIVE_CANCEL = 'INCENTIVE_CANCEL',
    INCENTIVE = 'INCENTIVE',
    DISPUTE = 'DISPUTE'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiChildTransactionDetailsTransactionStatusEnum {
    AUTHORIZED = 'AUTHORIZED',
    CANCELED = 'CANCELED',
    DISPUTE_CLOSED = 'DISPUTE_CLOSED',
    DISPUTE_OPEN = 'DISPUTE_OPEN',
    PENDING_SETTLED = 'PENDING_SETTLED',
    REFUNDED = 'REFUNDED',
    SETTLED = 'SETTLED',
    VOIDED = 'VOIDED',
    PARTIAL_CAPTURED = 'PARTIAL_CAPTURED',
    PARTIAL_REVERSED = 'PARTIAL_REVERSED',
    CAPTURED = 'CAPTURED',
    DECLINED = 'DECLINED',
    DISPUTED = 'DISPUTED',
    COMPLETE = 'COMPLETE',
    PENDING = 'PENDING',
    TIMED_OUT = 'TIMED_OUT',
    PERMANENT_FAILURE = 'PERMANENT_FAILURE',
    REPLACED_NSF = 'REPLACED_NSF',
    NO_AVAILABLE_BALANCE = 'NO_AVAILABLE_BALANCE',
    CANCELLED = 'CANCELLED',
    REVERSED = 'REVERSED'
}

/**
 * 
 * @export
 * @interface ApiCommentRequest
 */
export interface ApiCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiCommentRequest
     */
    'comment'?: string;
    /**
     * 
     * @type {Array<ApiAttachmentRequest>}
     * @memberof ApiCommentRequest
     */
    'attachments'?: Array<ApiAttachmentRequest>;
    /**
     * 
     * @type {string}
     * @memberof ApiCommentRequest
     */
    'actor'?: string;
}
/**
 * 
 * @export
 * @interface ApiContact
 */
export interface ApiContact {
    /**
     * 
     * @type {string}
     * @memberof ApiContact
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiContact
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiContact
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ApiDashBoardMetricsDetails
 */
export interface ApiDashBoardMetricsDetails {
    /**
     * 
     * @type {number}
     * @memberof ApiDashBoardMetricsDetails
     */
    'partyOnBoardedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiDashBoardMetricsDetails
     */
    'txnCreatedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiDashBoardMetricsDetails
     */
    'disputeCreatedCount'?: number;
}
/**
 * 
 * @export
 * @interface ApiDisputeChargebackRequest
 */
export interface ApiDisputeChargebackRequest {
    /**
     * 
     * @type {ApiCommentRequest}
     * @memberof ApiDisputeChargebackRequest
     */
    'comments'?: ApiCommentRequest;
    /**
     * 
     * @type {string}
     * @memberof ApiDisputeChargebackRequest
     */
    'actor'?: string;
}
/**
 * 
 * @export
 * @interface ApiEmbeddedPartnerConfiguration
 */
export interface ApiEmbeddedPartnerConfiguration {
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedPartnerConfiguration
     */
    'issuer'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedPartnerConfiguration
     */
    'serviceName'?: string;
}
/**
 * 
 * @export
 * @interface ApiError
 */
export interface ApiError {
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'code'?: string;
    /**
     * Human readable error message
     * @type {string}
     * @memberof ApiError
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ApiErrorDetail>}
     * @memberof ApiError
     */
    'details'?: Array<ApiErrorDetail>;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'trace'?: string;
}
/**
 * 
 * @export
 * @interface ApiErrorDetail
 */
export interface ApiErrorDetail {
    /**
     * 
     * @type {string}
     * @memberof ApiErrorDetail
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiErrorDetail
     */
    'field'?: string;
    /**
     * Human readable error message
     * @type {string}
     * @memberof ApiErrorDetail
     */
    'message'?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiErrorDetail
     */
    'rejectedValue'?: object;
}
/**
 * 
 * @export
 * @interface ApiMarqetaDisputeResponse
 */
export interface ApiMarqetaDisputeResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiMarqetaDisputeResponse
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarqetaDisputeResponse
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarqetaDisputeResponse
     */
    'memo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarqetaDisputeResponse
     */
    'program_short_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarqetaDisputeResponse
     */
    'user_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarqetaDisputeResponse
     */
    'business_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarqetaDisputeResponse
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarqetaDisputeResponse
     */
    'assignee'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarqetaDisputeResponse
     */
    'zendesk_ticket_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarqetaDisputeResponse
     */
    'type_change_time'?: string;
    /**
     * 
     * @type {MarqetaDisputeDetails}
     * @memberof ApiMarqetaDisputeResponse
     */
    'dispute_details'?: MarqetaDisputeDetails;
    /**
     * 
     * @type {string}
     * @memberof ApiMarqetaDisputeResponse
     */
    'created_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarqetaDisputeResponse
     */
    'updated_time'?: string;
}
/**
 * 
 * @export
 * @interface ApiPartner
 */
export interface ApiPartner {
    /**
     * 
     * @type {object}
     * @memberof ApiPartner
     */
    'id'?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiPartner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartner
     */
    'status'?: ApiPartnerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartner
     */
    'primaryCurrency'?: ApiPartnerPrimaryCurrencyEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPartner
     */
    'serviceTypes': Array<ApiPartnerServiceTypesEnum>;
    /**
     * 
     * @type {object}
     * @memberof ApiPartner
     */
    'parentPartnerId'?: object;
    /**
     * 
     * @type {object}
     * @memberof ApiPartner
     */
    'settlementPartnerId'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPartner
     */
    'ancestorPartnerIds'?: Array<string>;
    /**
     * 
     * @type {ApiPartnerConfiguration}
     * @memberof ApiPartner
     */
    'config'?: ApiPartnerConfiguration;
    /**
     * 
     * @type {ApiEmbeddedPartnerConfiguration}
     * @memberof ApiPartner
     */
    'embeddedConfig'?: ApiEmbeddedPartnerConfiguration;
    /**
     * 
     * @type {string}
     * @memberof ApiPartner
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartner
     */
    'bankName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartner
     */
    'routing'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartner
     */
    'account'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartner
     */
    'glCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartner
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartner
     */
    'fax'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiPartner
     */
    'trusted'?: boolean;
    /**
     * 
     * @type {ApiContact}
     * @memberof ApiPartner
     */
    'primary'?: ApiContact;
    /**
     * 
     * @type {ApiContact}
     * @memberof ApiPartner
     */
    'secondary'?: ApiContact;
    /**
     * 
     * @type {ApiContact}
     * @memberof ApiPartner
     */
    'operations'?: ApiContact;
    /**
     * 
     * @type {ApiContact}
     * @memberof ApiPartner
     */
    'customerSupport'?: ApiContact;
    /**
     * 
     * @type {ApiContact}
     * @memberof ApiPartner
     */
    'disputeSupport'?: ApiContact;
    /**
     * 
     * @type {ApiContact}
     * @memberof ApiPartner
     */
    'settlementSupport'?: ApiContact;
    /**
     * 
     * @type {string}
     * @memberof ApiPartner
     */
    'tenant'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiPartner
     */
    'allowIdDocSubmission'?: boolean;
    /**
     * 
     * @type {Array<ApiPartnerAccount>}
     * @memberof ApiPartner
     */
    'partnerAccounts'?: Array<ApiPartnerAccount>;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerStatusEnum {
    ACTIVE = 'ACTIVE',
    SUSPENDED = 'SUSPENDED',
    CLOSED = 'CLOSED',
    PENDING = 'PENDING'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerPrimaryCurrencyEnum {
    USD = 'USD',
    AAVE = 'AAVE',
    AVAX = 'AVAX',
    AXS = 'AXS',
    BAT = 'BAT',
    BCH = 'BCH',
    BTC = 'BTC',
    DOGE = 'DOGE',
    ETH = 'ETH',
    LINK = 'LINK',
    LTC = 'LTC',
    MANA = 'MANA',
    MATIC = 'MATIC',
    SOL = 'SOL',
    UNI = 'UNI',
    USDC = 'USDC',
    LXX = 'LXX',
    LCP = 'LCP',
    LWR = 'LWR',
    LMO = 'LMO',
    LKO = 'LKO',
    LED = 'LED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerServiceTypesEnum {
    CRYPTO_CURRENCY = 'CRYPTO_CURRENCY',
    REWARD = 'REWARD',
    PARTY = 'PARTY',
    PAYMENT = 'PAYMENT',
    PAYOUT = 'PAYOUT',
    LOYALTY = 'LOYALTY'
}

/**
 * 
 * @export
 * @interface ApiPartnerAccount
 */
export interface ApiPartnerAccount {
    /**
     * 
     * @type {object}
     * @memberof ApiPartnerAccount
     */
    'id'?: object;
    /**
     * 
     * @type {object}
     * @memberof ApiPartnerAccount
     */
    'partnerId'?: object;
    /**
     * 
     * @type {object}
     * @memberof ApiPartnerAccount
     */
    'ledgerAccountId'?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerAccount
     */
    'currency'?: ApiPartnerAccountCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerAccount
     */
    'bankName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerAccount
     */
    'routing'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerAccount
     */
    'account'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerAccount
     */
    'glCode'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPartnerAccount
     */
    'productTypes'?: Array<ApiPartnerAccountProductTypesEnum>;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerAccount
     */
    'status'?: ApiPartnerAccountStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerAccountCurrencyEnum {
    USD = 'USD',
    AAVE = 'AAVE',
    AVAX = 'AVAX',
    AXS = 'AXS',
    BAT = 'BAT',
    BCH = 'BCH',
    BTC = 'BTC',
    DOGE = 'DOGE',
    ETH = 'ETH',
    LINK = 'LINK',
    LTC = 'LTC',
    MANA = 'MANA',
    MATIC = 'MATIC',
    SOL = 'SOL',
    UNI = 'UNI',
    USDC = 'USDC',
    LXX = 'LXX',
    LCP = 'LCP',
    LWR = 'LWR',
    LMO = 'LMO',
    LKO = 'LKO',
    LED = 'LED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerAccountProductTypesEnum {
    CRYPTO_CONNECT = 'CRYPTO_CONNECT',
    CRYPTO_REWARDS = 'CRYPTO_REWARDS',
    CRYPTO_PAYOUT = 'CRYPTO_PAYOUT',
    PAYMENT = 'PAYMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerAccountStatusEnum {
    ACTIVE = 'ACTIVE',
    DELETED = 'DELETED'
}

/**
 * 
 * @export
 * @interface ApiPartnerConfiguration
 */
export interface ApiPartnerConfiguration {
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerConfiguration
     */
    'cryptoCurrencySource': ApiPartnerConfigurationCryptoCurrencySourceEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerConfiguration
     */
    'partyLevel': ApiPartnerConfigurationPartyLevelEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApiPartnerConfiguration
     */
    'kycCheckRequired': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPartnerConfiguration
     */
    'supportedOperations'?: Array<ApiPartnerConfigurationSupportedOperationsEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof ApiPartnerConfiguration
     */
    'allowIdDocSubmission'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerConfigurationCryptoCurrencySourceEnum {
    PARTNER = 'PARTNER',
    PARTY = 'PARTY'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerConfigurationPartyLevelEnum {
    UNKNOWN = 'UNKNOWN',
    LEVEL_1 = 'LEVEL_1',
    LEVEL_1_SUSPENDED = 'LEVEL_1_SUSPENDED',
    LEVEL_1_LIQUIDATED = 'LEVEL_1_LIQUIDATED',
    LEVEL_2 = 'LEVEL_2',
    LEVEL_2_SUSPENDED = 'LEVEL_2_SUSPENDED',
    LEVEL_2_LIQUIDATED = 'LEVEL_2_LIQUIDATED',
    LEVEL_3 = 'LEVEL_3',
    LEVEL_3_SUSPENDED = 'LEVEL_3_SUSPENDED',
    LEVEL_3_LIQUIDATED = 'LEVEL_3_LIQUIDATED',
    LEVEL_4 = 'LEVEL_4',
    LEVEL_4_SUSPENDED = 'LEVEL_4_SUSPENDED',
    LEVEL_4_LIQUIDATED = 'LEVEL_4_LIQUIDATED',
    LEVEL_5 = 'LEVEL_5',
    LEVEL_5_SUSPENDED = 'LEVEL_5_SUSPENDED',
    LEVEL_5_LIQUIDATED = 'LEVEL_5_LIQUIDATED',
    LEVEL_99 = 'LEVEL_99',
    LEVEL_99_SUSPENDED = 'LEVEL_99_SUSPENDED',
    LEVEL_99_LIQUIDATED = 'LEVEL_99_LIQUIDATED',
    CLOSED = 'CLOSED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerConfigurationSupportedOperationsEnum {
    REVERSE = 'REVERSE',
    CANCEL = 'CANCEL',
    DISPUTE = 'DISPUTE'
}

/**
 * 
 * @export
 * @interface ApiPartnerDisputeDetails
 */
export interface ApiPartnerDisputeDetails {
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerDisputeDetails
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerDisputeDetails
     */
    'disputeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerDisputeDetails
     */
    'status'?: ApiPartnerDisputeDetailsStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerDisputeDetails
     */
    'reasonCode'?: ApiPartnerDisputeDetailsReasonCodeEnum;
    /**
     * 
     * @type {object}
     * @memberof ApiPartnerDisputeDetails
     */
    'partyId'?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerDisputeDetails
     */
    'resolutionType'?: ApiPartnerDisputeDetailsResolutionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerDisputeDetails
     */
    'disputeType'?: ApiPartnerDisputeDetailsDisputeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerDisputeDetails
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerDisputeDetails
     */
    'currency'?: ApiPartnerDisputeDetailsCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerDisputeDetails
     */
    'partnerTicketId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerDisputeDetails
     */
    'partnerCaseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerDisputeDetails
     */
    'replyBy'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerDisputeDetailsStatusEnum {
    NEW = 'NEW',
    ESCALATED = 'ESCALATED',
    REJECTED = 'REJECTED',
    MERCHANT_REJECTED = 'MERCHANT_REJECTED',
    ACCEPTED = 'ACCEPTED',
    CANCELLED = 'CANCELLED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerDisputeDetailsReasonCodeEnum {
    DAC01 = 'DAC01',
    DAC02 = 'DAC02',
    DAC03 = 'DAC03',
    DAC04 = 'DAC04',
    DAC05 = 'DAC05',
    DAC06 = 'DAC06',
    DAC07 = 'DAC07',
    DAC08 = 'DAC08',
    DAC09 = 'DAC09',
    DAC10 = 'DAC10'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerDisputeDetailsResolutionTypeEnum {
    CHARGEBACK = 'CHARGEBACK',
    CHARGEBACK_BAKKT = 'CHARGEBACK_BAKKT',
    CREDIT = 'CREDIT',
    REFUND = 'REFUND'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerDisputeDetailsDisputeTypeEnum {
    MERCHANT_PAYMENT = 'MERCHANT_PAYMENT',
    DEPOSIT = 'DEPOSIT',
    WITHDRAW = 'WITHDRAW',
    CARD_PAYMENT = 'CARD_PAYMENT',
    MERCHANT_PAYMENT_V1 = 'MERCHANT_PAYMENT_V1',
    PARTNER_TRANSACTION = 'PARTNER_TRANSACTION'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerDisputeDetailsCurrencyEnum {
    USD = 'USD',
    AAVE = 'AAVE',
    AVAX = 'AVAX',
    AXS = 'AXS',
    BAT = 'BAT',
    BCH = 'BCH',
    BTC = 'BTC',
    DOGE = 'DOGE',
    ETH = 'ETH',
    LINK = 'LINK',
    LTC = 'LTC',
    MANA = 'MANA',
    MATIC = 'MATIC',
    SOL = 'SOL',
    UNI = 'UNI',
    USDC = 'USDC',
    LXX = 'LXX',
    LCP = 'LCP',
    LWR = 'LWR',
    LMO = 'LMO',
    LKO = 'LKO',
    LED = 'LED'
}

/**
 * 
 * @export
 * @interface ApiPartnerPortalUser
 */
export interface ApiPartnerPortalUser {
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPortalUser
     */
    'fusionAuthRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPortalUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPortalUser
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPortalUser
     */
    'lastName'?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof ApiPartnerPortalUser
     */
    'roles'?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPortalUser
     */
    'lastLoginTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPortalUser
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPortalUser
     */
    'userStatus'?: ApiPartnerPortalUserUserStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerPortalUser
     */
    'zendeskUserRef'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerPortalUserUserStatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    INVITED = 'INVITED'
}

/**
 * 
 * @export
 * @interface ApiPartnerSettlementFile
 */
export interface ApiPartnerSettlementFile {
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSettlementFile
     */
    'id'?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiPartnerSettlementFile
     */
    'partnerId'?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSettlementFile
     */
    'generationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSettlementFile
     */
    'fileDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSettlementFile
     */
    'filePath'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerSettlementFile
     */
    'transactionCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSettlementFile
     */
    'productType'?: ApiPartnerSettlementFileProductTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSettlementFile
     */
    'partnerType'?: ApiPartnerSettlementFilePartnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSettlementFile
     */
    'partnerName'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerSettlementFileProductTypeEnum {
    CRYPTO_CONNECT = 'CRYPTO_CONNECT',
    CRYPTO_REWARDS = 'CRYPTO_REWARDS',
    CRYPTO_PAYOUT = 'CRYPTO_PAYOUT',
    PAYMENT = 'PAYMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerSettlementFilePartnerTypeEnum {
    PARTNER = 'PARTNER',
    MERCHANT = 'MERCHANT',
    MERCHANT_V1 = 'MERCHANT_V1',
    LOYALTY_PROVIDER = 'LOYALTY_PROVIDER'
}

/**
 * 
 * @export
 * @interface ApiPartnerSpreadAndCommission
 */
export interface ApiPartnerSpreadAndCommission {
    /**
     * 
     * @type {object}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'partnerId'?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'partnerName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'partnerSpread'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'partnerSpreadModifiedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'partnerCommissionPercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'partnerCommissionPercentageModifiedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'partnerCommissionFlat'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'partnerCommissionFlatModifiedDate'?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'parentId'?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'parentName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'parentSpread'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'parentSpreadModifiedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'parentCommissionPercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'parentCommissionPercentageModifiedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'parentCommissionFlat'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'parentCommissionFlatModifiedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'bakktSpread'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'bakktSpreadModifiedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'bakktCommissionPercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'bakktCommissionPercentageModifiedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'bakktCommissionFlat'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'bakktCommissionFlatModifiedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'totalSpread'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'totalCommissionPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerSpreadAndCommission
     */
    'totalCommissionFlat'?: number;
}
/**
 * 
 * @export
 * @interface ApiPartnerSummary
 */
export interface ApiPartnerSummary {
    /**
     * 
     * @type {object}
     * @memberof ApiPartnerSummary
     */
    'id'?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSummary
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSummary
     */
    'status'?: ApiPartnerSummaryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSummary
     */
    'contactName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSummary
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSummary
     */
    'email'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerSummaryStatusEnum {
    ACTIVE = 'ACTIVE',
    SUSPENDED = 'SUSPENDED',
    CLOSED = 'CLOSED',
    PENDING = 'PENDING'
}

/**
 * 
 * @export
 * @interface ApiPartnerTransaction
 */
export interface ApiPartnerTransaction {
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    'partnerTransactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    'updated'?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiPartnerTransaction
     */
    'partnerId'?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    'partnerAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    'status'?: ApiPartnerTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    'transactionType'?: ApiPartnerTransactionTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    'operationType'?: ApiPartnerTransactionOperationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    'externalTransactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    'partnerTransactionRef'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransaction
     */
    'sourceAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransaction
     */
    'targetAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    'sourceCurrency'?: ApiPartnerTransactionSourceCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    'targetCurrency'?: ApiPartnerTransactionTargetCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    'disputeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    'disputeType'?: ApiPartnerTransactionDisputeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    'partnerCaseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    'escalateDate'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionStatusEnum {
    AUTHORIZED = 'AUTHORIZED',
    CANCELED = 'CANCELED',
    DISPUTE_CLOSED = 'DISPUTE_CLOSED',
    DISPUTE_OPEN = 'DISPUTE_OPEN',
    PENDING_SETTLED = 'PENDING_SETTLED',
    REFUNDED = 'REFUNDED',
    SETTLED = 'SETTLED',
    VOIDED = 'VOIDED',
    PARTIAL_CAPTURED = 'PARTIAL_CAPTURED',
    PARTIAL_REVERSED = 'PARTIAL_REVERSED',
    CAPTURED = 'CAPTURED',
    DECLINED = 'DECLINED',
    DISPUTED = 'DISPUTED',
    COMPLETE = 'COMPLETE',
    PENDING = 'PENDING',
    TIMED_OUT = 'TIMED_OUT',
    PERMANENT_FAILURE = 'PERMANENT_FAILURE',
    REPLACED_NSF = 'REPLACED_NSF',
    NO_AVAILABLE_BALANCE = 'NO_AVAILABLE_BALANCE',
    CANCELLED = 'CANCELLED',
    REVERSED = 'REVERSED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionTransactionTypeEnum {
    CRYPTO_CURRENCY = 'CRYPTO_CURRENCY',
    REWARD = 'REWARD',
    REDEMPTION_CANCEL = 'REDEMPTION_CANCEL',
    REDEMPTION = 'REDEMPTION',
    CREDIT = 'CREDIT',
    CREDIT_CANCEL = 'CREDIT_CANCEL',
    BUY = 'BUY',
    BUY_CANCEL = 'BUY_CANCEL',
    PAYMENT = 'PAYMENT',
    REFUND = 'REFUND',
    VOID = 'VOID',
    CHARGEBACK = 'CHARGEBACK',
    LOYALTY = 'LOYALTY',
    PAYOUT = 'PAYOUT',
    PAY_WITH_POINTS = 'PAY_WITH_POINTS',
    LOYALTY_CRYPTO_REDEEM_FAILED = 'LOYALTY_CRYPTO_REDEEM_FAILED',
    CONVERT_CANCEL = 'CONVERT_CANCEL',
    LOYALTY_BUY_CANCEL = 'LOYALTY_BUY_CANCEL',
    LOYALTY_BUY = 'LOYALTY_BUY',
    LOYALTY_REDEEM = 'LOYALTY_REDEEM',
    INCENTIVE_CANCEL = 'INCENTIVE_CANCEL',
    INCENTIVE = 'INCENTIVE',
    DISPUTE = 'DISPUTE'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionOperationTypeEnum {
    BUY = 'BUY',
    SELL = 'SELL',
    SELL_ALL = 'SELL_ALL',
    REWARD = 'REWARD',
    INCENTIVE = 'INCENTIVE',
    LOYALTY_BUY = 'LOYALTY_BUY',
    LOYALTY_CREDIT = 'LOYALTY_CREDIT',
    LOYALTY_REDEEM = 'LOYALTY_REDEEM',
    LOYALTY_BUY_CANCEL = 'LOYALTY_BUY_CANCEL',
    LOYALTY_REDEEM_CANCEL = 'LOYALTY_REDEEM_CANCEL',
    PAYOUT = 'PAYOUT',
    PAYMENT = 'PAYMENT',
    DISPUTE = 'DISPUTE'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionSourceCurrencyEnum {
    USD = 'USD',
    AAVE = 'AAVE',
    AVAX = 'AVAX',
    AXS = 'AXS',
    BAT = 'BAT',
    BCH = 'BCH',
    BTC = 'BTC',
    DOGE = 'DOGE',
    ETH = 'ETH',
    LINK = 'LINK',
    LTC = 'LTC',
    MANA = 'MANA',
    MATIC = 'MATIC',
    SOL = 'SOL',
    UNI = 'UNI',
    USDC = 'USDC',
    LXX = 'LXX',
    LCP = 'LCP',
    LWR = 'LWR',
    LMO = 'LMO',
    LKO = 'LKO',
    LED = 'LED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionTargetCurrencyEnum {
    USD = 'USD',
    AAVE = 'AAVE',
    AVAX = 'AVAX',
    AXS = 'AXS',
    BAT = 'BAT',
    BCH = 'BCH',
    BTC = 'BTC',
    DOGE = 'DOGE',
    ETH = 'ETH',
    LINK = 'LINK',
    LTC = 'LTC',
    MANA = 'MANA',
    MATIC = 'MATIC',
    SOL = 'SOL',
    UNI = 'UNI',
    USDC = 'USDC',
    LXX = 'LXX',
    LCP = 'LCP',
    LWR = 'LWR',
    LMO = 'LMO',
    LKO = 'LKO',
    LED = 'LED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionDisputeTypeEnum {
    MERCHANT_PAYMENT = 'MERCHANT_PAYMENT',
    DEPOSIT = 'DEPOSIT',
    WITHDRAW = 'WITHDRAW',
    CARD_PAYMENT = 'CARD_PAYMENT',
    MERCHANT_PAYMENT_V1 = 'MERCHANT_PAYMENT_V1',
    PARTNER_TRANSACTION = 'PARTNER_TRANSACTION'
}

/**
 * 
 * @export
 * @interface ApiPartnerTransactionDetails
 */
export interface ApiPartnerTransactionDetails {
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'cryptoCurrencySource'?: ApiPartnerTransactionDetailsCryptoCurrencySourceEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionDetails
     */
    'partnerAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'alias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'externalAccountRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'fundingSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'formattedFundingAccountNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionDetails
     */
    'unitPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'internalNotes'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionDetails
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionDetails
     */
    'marketPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionDetails
     */
    'totalSpread'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionDetails
     */
    'partnerSpread'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionDetails
     */
    'bakktSpread'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionDetails
     */
    'parentSpread'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionDetails
     */
    'totalCommission'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionDetails
     */
    'partnerCommission'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionDetails
     */
    'bakktCommission'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionDetails
     */
    'parentCommission'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionDetails
     */
    'pricePlusSpreadPlusCommission'?: number;
    /**
     * 
     * @type {object}
     * @memberof ApiPartnerTransactionDetails
     */
    'parentPartnerId'?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'linkedExternalTransactionRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'cancelTimestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'unitPriceCurrency'?: ApiPartnerTransactionDetailsUnitPriceCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'linkedLoyaltyTransactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'clientRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'experience'?: string;
    /**
     * 
     * @type {Amount}
     * @memberof ApiPartnerTransactionDetails
     */
    'bakktLoyaltyCreditAmount'?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof ApiPartnerTransactionDetails
     */
    'partnerLoyaltyCreditAmount'?: Amount;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'incentiveOfferRef'?: string;
    /**
     * 
     * @type {Array<ApiChildTransactionDetails>}
     * @memberof ApiPartnerTransactionDetails
     */
    'childTransactionDetails'?: Array<ApiChildTransactionDetails>;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionDetails
     */
    'capturedTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionDetails
     */
    'refundedTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionDetails
     */
    'authorizedAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'authorizedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'capturedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'canceledDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'reversedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'settledDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'merchantNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'reversalAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'captureType'?: ApiPartnerTransactionDetailsCaptureTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPartnerTransactionDetails
     */
    'captureAchFileId'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'payerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'reasonCode'?: ApiPartnerTransactionDetailsReasonCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'resolutionType'?: ApiPartnerTransactionDetailsResolutionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'partnerTicketId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'rejectionReasonId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionDetails
     */
    'fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'regulationEFlag'?: ApiPartnerTransactionDetailsRegulationEFlagEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'disputeCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionDetails
     */
    'ticketCreated'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionDetailsCryptoCurrencySourceEnum {
    PARTNER = 'PARTNER',
    PARTY = 'PARTY'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionDetailsUnitPriceCurrencyEnum {
    USD = 'USD',
    AAVE = 'AAVE',
    AVAX = 'AVAX',
    AXS = 'AXS',
    BAT = 'BAT',
    BCH = 'BCH',
    BTC = 'BTC',
    DOGE = 'DOGE',
    ETH = 'ETH',
    LINK = 'LINK',
    LTC = 'LTC',
    MANA = 'MANA',
    MATIC = 'MATIC',
    SOL = 'SOL',
    UNI = 'UNI',
    USDC = 'USDC',
    LXX = 'LXX',
    LCP = 'LCP',
    LWR = 'LWR',
    LMO = 'LMO',
    LKO = 'LKO',
    LED = 'LED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionDetailsCaptureTypeEnum {
    PARTIAL = 'PARTIAL',
    FINAL = 'FINAL'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionDetailsReasonCodeEnum {
    DAC01 = 'DAC01',
    DAC02 = 'DAC02',
    DAC03 = 'DAC03',
    DAC04 = 'DAC04',
    DAC05 = 'DAC05',
    DAC06 = 'DAC06',
    DAC07 = 'DAC07',
    DAC08 = 'DAC08',
    DAC09 = 'DAC09',
    DAC10 = 'DAC10'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionDetailsResolutionTypeEnum {
    CHARGEBACK = 'CHARGEBACK',
    CHARGEBACK_BAKKT = 'CHARGEBACK_BAKKT',
    CREDIT = 'CREDIT',
    REFUND = 'REFUND'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionDetailsRegulationEFlagEnum {
    YES = 'YES',
    NO = 'NO',
    NO_DISPOSITION = 'NO_DISPOSITION'
}

/**
 * 
 * @export
 * @interface ApiPartnerTransactionSummary
 */
export interface ApiPartnerTransactionSummary {
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'externalTransactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'partnerTransactionRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'partnerPartyRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'partnerPartyLinkId'?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiPartnerTransactionSummary
     */
    'partnerId'?: object;
    /**
     * 
     * @type {object}
     * @memberof ApiPartnerTransactionSummary
     */
    'partyId'?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'status'?: ApiPartnerTransactionSummaryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'transactionType'?: ApiPartnerTransactionSummaryTransactionTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionSummary
     */
    'sourceAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'sourceCurrency'?: ApiPartnerTransactionSummarySourceCurrencyEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionSummary
     */
    'targetAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'targetCurrency'?: ApiPartnerTransactionSummaryTargetCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'operationType'?: ApiPartnerTransactionSummaryOperationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'targetAssetType'?: ApiPartnerTransactionSummaryTargetAssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'internalNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'partnerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'declineReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'disputeType'?: ApiPartnerTransactionSummaryDisputeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'disputeStatus'?: ApiPartnerTransactionSummaryDisputeStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'escalateDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    'ticketRef'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionSummaryStatusEnum {
    AUTHORIZED = 'AUTHORIZED',
    CANCELED = 'CANCELED',
    DISPUTE_CLOSED = 'DISPUTE_CLOSED',
    DISPUTE_OPEN = 'DISPUTE_OPEN',
    PENDING_SETTLED = 'PENDING_SETTLED',
    REFUNDED = 'REFUNDED',
    SETTLED = 'SETTLED',
    VOIDED = 'VOIDED',
    PARTIAL_CAPTURED = 'PARTIAL_CAPTURED',
    PARTIAL_REVERSED = 'PARTIAL_REVERSED',
    CAPTURED = 'CAPTURED',
    DECLINED = 'DECLINED',
    DISPUTED = 'DISPUTED',
    COMPLETE = 'COMPLETE',
    PENDING = 'PENDING',
    TIMED_OUT = 'TIMED_OUT',
    PERMANENT_FAILURE = 'PERMANENT_FAILURE',
    REPLACED_NSF = 'REPLACED_NSF',
    NO_AVAILABLE_BALANCE = 'NO_AVAILABLE_BALANCE',
    CANCELLED = 'CANCELLED',
    REVERSED = 'REVERSED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionSummaryTransactionTypeEnum {
    CRYPTO_CURRENCY = 'CRYPTO_CURRENCY',
    REWARD = 'REWARD',
    REDEMPTION_CANCEL = 'REDEMPTION_CANCEL',
    REDEMPTION = 'REDEMPTION',
    CREDIT = 'CREDIT',
    CREDIT_CANCEL = 'CREDIT_CANCEL',
    BUY = 'BUY',
    BUY_CANCEL = 'BUY_CANCEL',
    PAYMENT = 'PAYMENT',
    REFUND = 'REFUND',
    VOID = 'VOID',
    CHARGEBACK = 'CHARGEBACK',
    LOYALTY = 'LOYALTY',
    PAYOUT = 'PAYOUT',
    PAY_WITH_POINTS = 'PAY_WITH_POINTS',
    LOYALTY_CRYPTO_REDEEM_FAILED = 'LOYALTY_CRYPTO_REDEEM_FAILED',
    CONVERT_CANCEL = 'CONVERT_CANCEL',
    LOYALTY_BUY_CANCEL = 'LOYALTY_BUY_CANCEL',
    LOYALTY_BUY = 'LOYALTY_BUY',
    LOYALTY_REDEEM = 'LOYALTY_REDEEM',
    INCENTIVE_CANCEL = 'INCENTIVE_CANCEL',
    INCENTIVE = 'INCENTIVE',
    DISPUTE = 'DISPUTE'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionSummarySourceCurrencyEnum {
    USD = 'USD',
    AAVE = 'AAVE',
    AVAX = 'AVAX',
    AXS = 'AXS',
    BAT = 'BAT',
    BCH = 'BCH',
    BTC = 'BTC',
    DOGE = 'DOGE',
    ETH = 'ETH',
    LINK = 'LINK',
    LTC = 'LTC',
    MANA = 'MANA',
    MATIC = 'MATIC',
    SOL = 'SOL',
    UNI = 'UNI',
    USDC = 'USDC',
    LXX = 'LXX',
    LCP = 'LCP',
    LWR = 'LWR',
    LMO = 'LMO',
    LKO = 'LKO',
    LED = 'LED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionSummaryTargetCurrencyEnum {
    USD = 'USD',
    AAVE = 'AAVE',
    AVAX = 'AVAX',
    AXS = 'AXS',
    BAT = 'BAT',
    BCH = 'BCH',
    BTC = 'BTC',
    DOGE = 'DOGE',
    ETH = 'ETH',
    LINK = 'LINK',
    LTC = 'LTC',
    MANA = 'MANA',
    MATIC = 'MATIC',
    SOL = 'SOL',
    UNI = 'UNI',
    USDC = 'USDC',
    LXX = 'LXX',
    LCP = 'LCP',
    LWR = 'LWR',
    LMO = 'LMO',
    LKO = 'LKO',
    LED = 'LED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionSummaryOperationTypeEnum {
    BUY = 'BUY',
    SELL = 'SELL',
    SELL_ALL = 'SELL_ALL',
    REWARD = 'REWARD',
    INCENTIVE = 'INCENTIVE',
    LOYALTY_BUY = 'LOYALTY_BUY',
    LOYALTY_CREDIT = 'LOYALTY_CREDIT',
    LOYALTY_REDEEM = 'LOYALTY_REDEEM',
    LOYALTY_BUY_CANCEL = 'LOYALTY_BUY_CANCEL',
    LOYALTY_REDEEM_CANCEL = 'LOYALTY_REDEEM_CANCEL',
    PAYOUT = 'PAYOUT',
    PAYMENT = 'PAYMENT',
    DISPUTE = 'DISPUTE'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionSummaryTargetAssetTypeEnum {
    CASH = 'CASH',
    CRYPTO = 'CRYPTO',
    REWARDS = 'REWARDS',
    GIFT_CARD = 'GIFT_CARD',
    BAKKT_CARD = 'BAKKT_CARD',
    DEBIT_CARD = 'DEBIT_CARD'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionSummaryDisputeTypeEnum {
    MERCHANT_PAYMENT = 'MERCHANT_PAYMENT',
    DEPOSIT = 'DEPOSIT',
    WITHDRAW = 'WITHDRAW',
    CARD_PAYMENT = 'CARD_PAYMENT',
    MERCHANT_PAYMENT_V1 = 'MERCHANT_PAYMENT_V1',
    PARTNER_TRANSACTION = 'PARTNER_TRANSACTION'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionSummaryDisputeStatusEnum {
    NEW = 'NEW',
    ESCALATED = 'ESCALATED',
    REJECTED = 'REJECTED',
    MERCHANT_REJECTED = 'MERCHANT_REJECTED',
    ACCEPTED = 'ACCEPTED',
    CANCELLED = 'CANCELLED'
}

/**
 * 
 * @export
 * @interface ApiPartnerUserInfo
 */
export interface ApiPartnerUserInfo {
    /**
     * 
     * @type {Set<string>}
     * @memberof ApiPartnerUserInfo
     */
    'roles'?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof ApiPartnerUserInfo
     */
    'permissions'?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerUserInfo
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiPartnerUserInfo
     */
    'isBakktUser'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerUserInfo
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ApiPartnerUserInfo
     */
    'partners'?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPartnerUserInfo
     */
    'supportedOperations'?: Array<ApiPartnerUserInfoSupportedOperationsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPartnerUserInfo
     */
    'subscribedApis'?: Array<ApiPartnerUserInfoSubscribedApisEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerUserInfoSupportedOperationsEnum {
    REVERSE = 'REVERSE',
    CANCEL = 'CANCEL',
    DISPUTE = 'DISPUTE'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerUserInfoSubscribedApisEnum {
    CRYPTO_CONNECT = 'CRYPTO_CONNECT',
    COMPANION_WEB = 'COMPANION_WEB',
    EMBEDDED_WEB = 'EMBEDDED_WEB',
    PAYMENT = 'PAYMENT',
    PAYOUT = 'PAYOUT'
}

/**
 * 
 * @export
 * @interface ApiParty
 */
export interface ApiParty {
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'updated'?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiParty
     */
    'id'?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'alias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'level'?: ApiPartyLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'status'?: ApiPartyStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartyLevelEnum {
    UNKNOWN = 'UNKNOWN',
    LEVEL_1 = 'LEVEL_1',
    LEVEL_1_SUSPENDED = 'LEVEL_1_SUSPENDED',
    LEVEL_1_LIQUIDATED = 'LEVEL_1_LIQUIDATED',
    LEVEL_2 = 'LEVEL_2',
    LEVEL_2_SUSPENDED = 'LEVEL_2_SUSPENDED',
    LEVEL_2_LIQUIDATED = 'LEVEL_2_LIQUIDATED',
    LEVEL_3 = 'LEVEL_3',
    LEVEL_3_SUSPENDED = 'LEVEL_3_SUSPENDED',
    LEVEL_3_LIQUIDATED = 'LEVEL_3_LIQUIDATED',
    LEVEL_4 = 'LEVEL_4',
    LEVEL_4_SUSPENDED = 'LEVEL_4_SUSPENDED',
    LEVEL_4_LIQUIDATED = 'LEVEL_4_LIQUIDATED',
    LEVEL_5 = 'LEVEL_5',
    LEVEL_5_SUSPENDED = 'LEVEL_5_SUSPENDED',
    LEVEL_5_LIQUIDATED = 'LEVEL_5_LIQUIDATED',
    LEVEL_99 = 'LEVEL_99',
    LEVEL_99_SUSPENDED = 'LEVEL_99_SUSPENDED',
    LEVEL_99_LIQUIDATED = 'LEVEL_99_LIQUIDATED',
    CLOSED = 'CLOSED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartyStatusEnum {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    SUSPENDED = 'SUSPENDED',
    LIQUIDATED = 'LIQUIDATED'
}

/**
 * 
 * @export
 * @interface ApiPartyBaseSearchResponse
 */
export interface ApiPartyBaseSearchResponse {
    /**
     * 
     * @type {object}
     * @memberof ApiPartyBaseSearchResponse
     */
    'id'?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    'partnerPartyLinkId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    'alias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    'created'?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiPartyBaseSearchResponse
     */
    'partnerId'?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    'partnerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    'pplStatus'?: ApiPartyBaseSearchResponsePplStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    'partnerPartyRef'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartyBaseSearchResponsePplStatusEnum {
    ACTIVE = 'ACTIVE',
    OTP_REQUIRED = 'OTP_REQUIRED',
    PENDING = 'PENDING',
    TRANSFERRED = 'TRANSFERRED',
    UNDER_REVIEW = 'UNDER_REVIEW',
    INACTIVE = 'INACTIVE'
}

/**
 * 
 * @export
 * @interface ApiPartyDashboardSearch
 */
export interface ApiPartyDashboardSearch {
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDashboardSearch
     */
    'partnerPartyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDashboardSearch
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPartyDashboardSearch
     */
    'emails'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPartyDashboardSearch
     */
    'phones'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPartyDashboardSearch
     */
    'partnerIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ApiPartyDetails
 */
export interface ApiPartyDetails {
    /**
     * 
     * @type {object}
     * @memberof ApiPartyDetails
     */
    'id'?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetails
     */
    'partnerPartyLinkId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetails
     */
    'partnerPartyRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetails
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetails
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetails
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetails
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetails
     */
    'alias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetails
     */
    'level'?: ApiPartyDetailsLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetails
     */
    'fullName'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartyDetailsLevelEnum {
    UNKNOWN = 'UNKNOWN',
    LEVEL_1 = 'LEVEL_1',
    LEVEL_1_SUSPENDED = 'LEVEL_1_SUSPENDED',
    LEVEL_1_LIQUIDATED = 'LEVEL_1_LIQUIDATED',
    LEVEL_2 = 'LEVEL_2',
    LEVEL_2_SUSPENDED = 'LEVEL_2_SUSPENDED',
    LEVEL_2_LIQUIDATED = 'LEVEL_2_LIQUIDATED',
    LEVEL_3 = 'LEVEL_3',
    LEVEL_3_SUSPENDED = 'LEVEL_3_SUSPENDED',
    LEVEL_3_LIQUIDATED = 'LEVEL_3_LIQUIDATED',
    LEVEL_4 = 'LEVEL_4',
    LEVEL_4_SUSPENDED = 'LEVEL_4_SUSPENDED',
    LEVEL_4_LIQUIDATED = 'LEVEL_4_LIQUIDATED',
    LEVEL_5 = 'LEVEL_5',
    LEVEL_5_SUSPENDED = 'LEVEL_5_SUSPENDED',
    LEVEL_5_LIQUIDATED = 'LEVEL_5_LIQUIDATED',
    LEVEL_99 = 'LEVEL_99',
    LEVEL_99_SUSPENDED = 'LEVEL_99_SUSPENDED',
    LEVEL_99_LIQUIDATED = 'LEVEL_99_LIQUIDATED',
    CLOSED = 'CLOSED'
}

/**
 * 
 * @export
 * @interface ApiPartyDetailsRequest
 */
export interface ApiPartyDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetailsRequest
     */
    'partnerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetailsRequest
     */
    'partnerPartyLinkId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetailsRequest
     */
    'partnerPartyRef'?: string;
}
/**
 * 
 * @export
 * @interface ApiPartyPortfolio
 */
export interface ApiPartyPortfolio {
    /**
     * 
     * @type {Array<PortfolioByAsset>}
     * @memberof ApiPartyPortfolio
     */
    'portfolioItems'?: Array<PortfolioByAsset>;
    /**
     * 
     * @type {ServiceAmount}
     * @memberof ApiPartyPortfolio
     */
    'totalPortfolioAmount'?: ServiceAmount;
}
/**
 * 
 * @export
 * @interface ApiPortalRole
 */
export interface ApiPortalRole {
    /**
     * 
     * @type {string}
     * @memberof ApiPortalRole
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPortalRole
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPortalRole
     */
    'fusionAuthRoleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPortalRole
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiPortalRole
     */
    'superRole'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiPortalRole
     */
    'systemRole'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiPortalRole
     */
    'roleType'?: ApiPortalRoleRoleTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPortalRole
     */
    'permissions'?: Array<ApiPortalRolePermissionsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPortalRoleRoleTypeEnum {
    BAKKT = 'BAKKT',
    PARTNER_GENERIC = 'PARTNER_GENERIC',
    PARTNER_SPECIFIC = 'PARTNER_SPECIFIC'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPortalRolePermissionsEnum {
    ADMIN_CREATE = 'ADMIN_CREATE',
    ADMIN_UPDATE = 'ADMIN_UPDATE',
    CONSUMER_VIEW = 'CONSUMER_VIEW',
    CONSUMER_VIEW_DETAIL = 'CONSUMER_VIEW_DETAIL',
    USERS_CREATE = 'USERS_CREATE',
    USERS_UPDATE = 'USERS_UPDATE',
    USERS_UPDATE_SYNC = 'USERS_UPDATE_SYNC',
    USERS_VIEW = 'USERS_VIEW',
    USERS_VIEW_DETAIL = 'USERS_VIEW_DETAIL',
    ROLE_CREATE_UPDATE = 'ROLE_CREATE_UPDATE',
    ROLE_VIEW = 'ROLE_VIEW',
    TRANSACTION_VIEW = 'TRANSACTION_VIEW',
    TRANSACTION_VIEW_DETAIL = 'TRANSACTION_VIEW_DETAIL',
    TRANSACTION_CANCEL = 'TRANSACTION_CANCEL',
    TRANSACTION_REVERSE = 'TRANSACTION_REVERSE',
    PROFILE_UPDATE = 'PROFILE_UPDATE',
    PROFILE_VIEW = 'PROFILE_VIEW',
    SETTLEMENT_REPORT_VIEW = 'SETTLEMENT_REPORT_VIEW',
    SLA_REPORT_VIEW = 'SLA_REPORT_VIEW',
    DISPUTE_VIEW = 'DISPUTE_VIEW',
    DISPUTE_ACCEPT = 'DISPUTE_ACCEPT',
    DISPUTE_ADD_COMMENT = 'DISPUTE_ADD_COMMENT',
    DISPUTE_DOWNLOAD_ATTACHMENT = 'DISPUTE_DOWNLOAD_ATTACHMENT',
    DISPUTE_FILE_UPLOAD = 'DISPUTE_FILE_UPLOAD',
    DISPUTE_REJECT = 'DISPUTE_REJECT',
    DISPUTE_VIEW_COMMENT = 'DISPUTE_VIEW_COMMENT',
    TICKET_ADD_COMMENT = 'TICKET_ADD_COMMENT',
    TICKET_UPDATE_COMMENT = 'TICKET_UPDATE_COMMENT',
    TICKET_RETRIEVE_COMMENT = 'TICKET_RETRIEVE_COMMENT',
    UNMASK_ACCOUNT_NUMBER = 'UNMASK_ACCOUNT_NUMBER',
    UNMASK_ROUTING_NUMBER = 'UNMASK_ROUTING_NUMBER',
    ID_DOC_SUBMISSION_VIEW = 'ID_DOC_SUBMISSION_VIEW'
}

/**
 * 
 * @export
 * @interface ApiResponseApiAttachment
 */
export interface ApiResponseApiAttachment {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiAttachment
     */
    'success'?: boolean;
    /**
     * 
     * @type {ApiAttachment}
     * @memberof ApiResponseApiAttachment
     */
    'payload'?: ApiAttachment;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiAttachment
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiAttachment
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponseApiDashBoardMetricsDetails
 */
export interface ApiResponseApiDashBoardMetricsDetails {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiDashBoardMetricsDetails
     */
    'success'?: boolean;
    /**
     * 
     * @type {ApiDashBoardMetricsDetails}
     * @memberof ApiResponseApiDashBoardMetricsDetails
     */
    'payload'?: ApiDashBoardMetricsDetails;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiDashBoardMetricsDetails
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiDashBoardMetricsDetails
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponseApiMarqetaDisputeResponse
 */
export interface ApiResponseApiMarqetaDisputeResponse {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiMarqetaDisputeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {ApiMarqetaDisputeResponse}
     * @memberof ApiResponseApiMarqetaDisputeResponse
     */
    'payload'?: ApiMarqetaDisputeResponse;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiMarqetaDisputeResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiMarqetaDisputeResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponseApiPartner
 */
export interface ApiResponseApiPartner {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiPartner
     */
    'success'?: boolean;
    /**
     * 
     * @type {ApiPartner}
     * @memberof ApiResponseApiPartner
     */
    'payload'?: ApiPartner;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiPartner
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiPartner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponseApiPartnerPortalUser
 */
export interface ApiResponseApiPartnerPortalUser {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiPartnerPortalUser
     */
    'success'?: boolean;
    /**
     * 
     * @type {ApiPartnerPortalUser}
     * @memberof ApiResponseApiPartnerPortalUser
     */
    'payload'?: ApiPartnerPortalUser;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiPartnerPortalUser
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiPartnerPortalUser
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponseApiPartnerSpreadAndCommission
 */
export interface ApiResponseApiPartnerSpreadAndCommission {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiPartnerSpreadAndCommission
     */
    'success'?: boolean;
    /**
     * 
     * @type {ApiPartnerSpreadAndCommission}
     * @memberof ApiResponseApiPartnerSpreadAndCommission
     */
    'payload'?: ApiPartnerSpreadAndCommission;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiPartnerSpreadAndCommission
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiPartnerSpreadAndCommission
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponseApiParty
 */
export interface ApiResponseApiParty {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiParty
     */
    'success'?: boolean;
    /**
     * 
     * @type {ApiParty}
     * @memberof ApiResponseApiParty
     */
    'payload'?: ApiParty;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiParty
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiParty
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponseApiPartyDetails
 */
export interface ApiResponseApiPartyDetails {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiPartyDetails
     */
    'success'?: boolean;
    /**
     * 
     * @type {ApiPartyDetails}
     * @memberof ApiResponseApiPartyDetails
     */
    'payload'?: ApiPartyDetails;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiPartyDetails
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiPartyDetails
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponseApiPartyPortfolio
 */
export interface ApiResponseApiPartyPortfolio {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiPartyPortfolio
     */
    'success'?: boolean;
    /**
     * 
     * @type {ApiPartyPortfolio}
     * @memberof ApiResponseApiPartyPortfolio
     */
    'payload'?: ApiPartyPortfolio;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiPartyPortfolio
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiPartyPortfolio
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponseApiTransactionDetails
 */
export interface ApiResponseApiTransactionDetails {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiTransactionDetails
     */
    'success'?: boolean;
    /**
     * 
     * @type {ApiTransactionDetails}
     * @memberof ApiResponseApiTransactionDetails
     */
    'payload'?: ApiTransactionDetails;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiTransactionDetails
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiTransactionDetails
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponseListApiMarqetaDisputeResponse
 */
export interface ApiResponseListApiMarqetaDisputeResponse {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseListApiMarqetaDisputeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ApiMarqetaDisputeResponse>}
     * @memberof ApiResponseListApiMarqetaDisputeResponse
     */
    'payload'?: Array<ApiMarqetaDisputeResponse>;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseListApiMarqetaDisputeResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseListApiMarqetaDisputeResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponseListApiPartnerSummary
 */
export interface ApiResponseListApiPartnerSummary {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseListApiPartnerSummary
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ApiPartnerSummary>}
     * @memberof ApiResponseListApiPartnerSummary
     */
    'payload'?: Array<ApiPartnerSummary>;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseListApiPartnerSummary
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseListApiPartnerSummary
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponseListApiPortalRole
 */
export interface ApiResponseListApiPortalRole {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseListApiPortalRole
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ApiPortalRole>}
     * @memberof ApiResponseListApiPortalRole
     */
    'payload'?: Array<ApiPortalRole>;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseListApiPortalRole
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseListApiPortalRole
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiPartnerPortalUser
 */
export interface ApiResponsePageApiPartnerPortalUser {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiPartnerPortalUser
     */
    'success'?: boolean;
    /**
     * 
     * @type {PageApiPartnerPortalUser}
     * @memberof ApiResponsePageApiPartnerPortalUser
     */
    'payload'?: PageApiPartnerPortalUser;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiPartnerPortalUser
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiPartnerPortalUser
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiPartnerSettlementFile
 */
export interface ApiResponsePageApiPartnerSettlementFile {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiPartnerSettlementFile
     */
    'success'?: boolean;
    /**
     * 
     * @type {PageApiPartnerSettlementFile}
     * @memberof ApiResponsePageApiPartnerSettlementFile
     */
    'payload'?: PageApiPartnerSettlementFile;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiPartnerSettlementFile
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiPartnerSettlementFile
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiPartnerTransaction
 */
export interface ApiResponsePageApiPartnerTransaction {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiPartnerTransaction
     */
    'success'?: boolean;
    /**
     * 
     * @type {PageApiPartnerTransaction}
     * @memberof ApiResponsePageApiPartnerTransaction
     */
    'payload'?: PageApiPartnerTransaction;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiPartnerTransaction
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiPartnerTransaction
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiPartnerTransactionSummary
 */
export interface ApiResponsePageApiPartnerTransactionSummary {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiPartnerTransactionSummary
     */
    'success'?: boolean;
    /**
     * 
     * @type {PageApiPartnerTransactionSummary}
     * @memberof ApiResponsePageApiPartnerTransactionSummary
     */
    'payload'?: PageApiPartnerTransactionSummary;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiPartnerTransactionSummary
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiPartnerTransactionSummary
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiParty
 */
export interface ApiResponsePageApiParty {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiParty
     */
    'success'?: boolean;
    /**
     * 
     * @type {PageApiParty}
     * @memberof ApiResponsePageApiParty
     */
    'payload'?: PageApiParty;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiParty
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiParty
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiPartyBaseSearchResponse
 */
export interface ApiResponsePageApiPartyBaseSearchResponse {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiPartyBaseSearchResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {PageApiPartyBaseSearchResponse}
     * @memberof ApiResponsePageApiPartyBaseSearchResponse
     */
    'payload'?: PageApiPartyBaseSearchResponse;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiPartyBaseSearchResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiPartyBaseSearchResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiSLAReport
 */
export interface ApiResponsePageApiSLAReport {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiSLAReport
     */
    'success'?: boolean;
    /**
     * 
     * @type {PageApiSLAReport}
     * @memberof ApiResponsePageApiSLAReport
     */
    'payload'?: PageApiSLAReport;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiSLAReport
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiSLAReport
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponseString
 */
export interface ApiResponseString {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseString
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseString
     */
    'payload'?: string;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseString
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseString
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponseURL
 */
export interface ApiResponseURL {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseURL
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseURL
     */
    'payload'?: string;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseURL
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseURL
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponseUUID
 */
export interface ApiResponseUUID {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseUUID
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseUUID
     */
    'payload'?: string;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseUUID
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseUUID
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResponseVoid
 */
export interface ApiResponseVoid {
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseVoid
     */
    'success'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ApiResponseVoid
     */
    'payload'?: object;
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseVoid
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseVoid
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiSLA
 */
export interface ApiSLA {
    /**
     * 
     * @type {number}
     * @memberof ApiSLA
     */
    'availability'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiSLA
     */
    'responseTime'?: number;
}
/**
 * 
 * @export
 * @interface ApiSLAReport
 */
export interface ApiSLAReport {
    /**
     * 
     * @type {string}
     * @memberof ApiSLAReport
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSLAReport
     */
    'date'?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiSLAReport
     */
    'partnerId': object;
    /**
     * 
     * @type {ApiSLA}
     * @memberof ApiSLAReport
     */
    'companionWeb'?: ApiSLA;
    /**
     * 
     * @type {ApiSLA}
     * @memberof ApiSLAReport
     */
    'embeddedWeb'?: ApiSLA;
    /**
     * 
     * @type {ApiSLA}
     * @memberof ApiSLAReport
     */
    'paymentApi'?: ApiSLA;
    /**
     * 
     * @type {ApiSLA}
     * @memberof ApiSLAReport
     */
    'cryptoApi'?: ApiSLA;
    /**
     * 
     * @type {ApiSLA}
     * @memberof ApiSLAReport
     */
    'payoutApi'?: ApiSLA;
    /**
     * 
     * @type {string}
     * @memberof ApiSLAReport
     */
    'batchCycleCompletion'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiSLAReport
     */
    'ticketCreated'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiSLAReport
     */
    'ticketPending'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiSLAReport
     */
    'ticketClosed'?: number;
}
/**
 * 
 * @export
 * @interface ApiTransactionActivity
 */
export interface ApiTransactionActivity {
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionActivity
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionActivity
     */
    'partnerTransactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionActivity
     */
    'partnerTransactionRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionActivity
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionActivity
     */
    'activity'?: ApiTransactionActivityActivityEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionActivity
     */
    'actor'?: string;
    /**
     * 
     * @type {Array<AttachmentDetails>}
     * @memberof ApiTransactionActivity
     */
    'attachments'?: Array<AttachmentDetails>;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiTransactionActivityActivityEnum {
    INITIATED = 'INITIATED',
    AUTHORIZED = 'AUTHORIZED',
    REAUTHORIZED = 'REAUTHORIZED',
    AUTH_DECLINED = 'AUTH_DECLINED',
    CAPTURED = 'CAPTURED',
    REVERSED = 'REVERSED',
    SETTLED = 'SETTLED',
    DISPUTED = 'DISPUTED',
    ESCALATED = 'ESCALATED',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    CHARGEBACK = 'CHARGEBACK',
    FORCE_CHARGEBACK = 'FORCE_CHARGEBACK',
    MERCHANT_REJECTED = 'MERCHANT_REJECTED',
    MERCHANT_ACCEPTED = 'MERCHANT_ACCEPTED',
    MERCHANT_RE_ESCALATED = 'MERCHANT_RE_ESCALATED',
    CANCELLED = 'CANCELLED',
    MERCHANT_ESCALATED = 'MERCHANT_ESCALATED',
    DISPUTE_CANCELED = 'DISPUTE_CANCELED',
    ADD_COMMENT = 'ADD_COMMENT',
    REGULATION_E = 'REGULATION_E',
    PROVISIONAL_CREDIT = 'PROVISIONAL_CREDIT',
    CANCELED = 'CANCELED',
    COMPLETE = 'COMPLETE',
    EXPIRED = 'EXPIRED',
    REPLACED = 'REPLACED',
    PENDING = 'PENDING',
    CONVERT_CANCEL = 'CONVERT_CANCEL',
    DECLINED = 'DECLINED',
    REFUNDED = 'REFUNDED',
    CREDITED = 'CREDITED',
    INCENTIVE = 'INCENTIVE',
    INCENTIVE_CANCEL = 'INCENTIVE_CANCEL',
    LOYALTY_BUY = 'LOYALTY_BUY',
    LOYALTY_BUY_CANCEL = 'LOYALTY_BUY_CANCEL',
    LOYALTY_CREDIT_CANCEL = 'LOYALTY_CREDIT_CANCEL',
    LOYALTY_REDEEM = 'LOYALTY_REDEEM',
    LOYALTY_CRYPTO_REDEEM_FAILED = 'LOYALTY_CRYPTO_REDEEM_FAILED',
    LOYALTY_PAYMENT_REVERSAL = 'LOYALTY_PAYMENT_REVERSAL',
    FAILED = 'FAILED',
    PROVISIONAL = 'PROVISIONAL'
}

/**
 * 
 * @export
 * @interface ApiTransactionDetails
 */
export interface ApiTransactionDetails {
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'partnerTransactionRef'?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiTransactionDetails
     */
    'partyId'?: object;
    /**
     * 
     * @type {object}
     * @memberof ApiTransactionDetails
     */
    'partnerId'?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'transactionType'?: ApiTransactionDetailsTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'status'?: ApiTransactionDetailsStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'partnerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'partnerPartyRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'partnerPartyLinkId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiTransactionDetails
     */
    'sourceAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiTransactionDetails
     */
    'targetAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'sourceCurrency'?: ApiTransactionDetailsSourceCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'targetCurrency'?: ApiTransactionDetailsTargetCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'operationType'?: ApiTransactionDetailsOperationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'externalTransactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'cryptoCurrencySource'?: ApiTransactionDetailsCryptoCurrencySourceEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'externalAccountRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'fundingSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'formattedFundingAccountNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiTransactionDetails
     */
    'unitPrice'?: number;
    /**
     * 
     * @type {ApiPartnerDisputeDetails}
     * @memberof ApiTransactionDetails
     */
    'disputeDetails'?: ApiPartnerDisputeDetails;
    /**
     * 
     * @type {Array<ApiTransactionActivity>}
     * @memberof ApiTransactionDetails
     */
    'activity'?: Array<ApiTransactionActivity>;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'bankName'?: string;
    /**
     * 
     * @type {ApiPartnerTransactionDetails}
     * @memberof ApiTransactionDetails
     */
    'partnerTransactionDetails'?: ApiPartnerTransactionDetails;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    'fullName'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiTransactionDetailsTransactionTypeEnum {
    CRYPTO_CURRENCY = 'CRYPTO_CURRENCY',
    REWARD = 'REWARD',
    REDEMPTION_CANCEL = 'REDEMPTION_CANCEL',
    REDEMPTION = 'REDEMPTION',
    CREDIT = 'CREDIT',
    CREDIT_CANCEL = 'CREDIT_CANCEL',
    BUY = 'BUY',
    BUY_CANCEL = 'BUY_CANCEL',
    PAYMENT = 'PAYMENT',
    REFUND = 'REFUND',
    VOID = 'VOID',
    CHARGEBACK = 'CHARGEBACK',
    LOYALTY = 'LOYALTY',
    PAYOUT = 'PAYOUT',
    PAY_WITH_POINTS = 'PAY_WITH_POINTS',
    LOYALTY_CRYPTO_REDEEM_FAILED = 'LOYALTY_CRYPTO_REDEEM_FAILED',
    CONVERT_CANCEL = 'CONVERT_CANCEL',
    LOYALTY_BUY_CANCEL = 'LOYALTY_BUY_CANCEL',
    LOYALTY_BUY = 'LOYALTY_BUY',
    LOYALTY_REDEEM = 'LOYALTY_REDEEM',
    INCENTIVE_CANCEL = 'INCENTIVE_CANCEL',
    INCENTIVE = 'INCENTIVE',
    DISPUTE = 'DISPUTE'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiTransactionDetailsStatusEnum {
    AUTHORIZED = 'AUTHORIZED',
    CANCELED = 'CANCELED',
    DISPUTE_CLOSED = 'DISPUTE_CLOSED',
    DISPUTE_OPEN = 'DISPUTE_OPEN',
    PENDING_SETTLED = 'PENDING_SETTLED',
    REFUNDED = 'REFUNDED',
    SETTLED = 'SETTLED',
    VOIDED = 'VOIDED',
    PARTIAL_CAPTURED = 'PARTIAL_CAPTURED',
    PARTIAL_REVERSED = 'PARTIAL_REVERSED',
    CAPTURED = 'CAPTURED',
    DECLINED = 'DECLINED',
    DISPUTED = 'DISPUTED',
    COMPLETE = 'COMPLETE',
    PENDING = 'PENDING',
    TIMED_OUT = 'TIMED_OUT',
    PERMANENT_FAILURE = 'PERMANENT_FAILURE',
    REPLACED_NSF = 'REPLACED_NSF',
    NO_AVAILABLE_BALANCE = 'NO_AVAILABLE_BALANCE',
    CANCELLED = 'CANCELLED',
    REVERSED = 'REVERSED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiTransactionDetailsSourceCurrencyEnum {
    USD = 'USD',
    AAVE = 'AAVE',
    AVAX = 'AVAX',
    AXS = 'AXS',
    BAT = 'BAT',
    BCH = 'BCH',
    BTC = 'BTC',
    DOGE = 'DOGE',
    ETH = 'ETH',
    LINK = 'LINK',
    LTC = 'LTC',
    MANA = 'MANA',
    MATIC = 'MATIC',
    SOL = 'SOL',
    UNI = 'UNI',
    USDC = 'USDC',
    LXX = 'LXX',
    LCP = 'LCP',
    LWR = 'LWR',
    LMO = 'LMO',
    LKO = 'LKO',
    LED = 'LED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiTransactionDetailsTargetCurrencyEnum {
    USD = 'USD',
    AAVE = 'AAVE',
    AVAX = 'AVAX',
    AXS = 'AXS',
    BAT = 'BAT',
    BCH = 'BCH',
    BTC = 'BTC',
    DOGE = 'DOGE',
    ETH = 'ETH',
    LINK = 'LINK',
    LTC = 'LTC',
    MANA = 'MANA',
    MATIC = 'MATIC',
    SOL = 'SOL',
    UNI = 'UNI',
    USDC = 'USDC',
    LXX = 'LXX',
    LCP = 'LCP',
    LWR = 'LWR',
    LMO = 'LMO',
    LKO = 'LKO',
    LED = 'LED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiTransactionDetailsOperationTypeEnum {
    BUY = 'BUY',
    SELL = 'SELL',
    SELL_ALL = 'SELL_ALL',
    REWARD = 'REWARD',
    INCENTIVE = 'INCENTIVE',
    LOYALTY_BUY = 'LOYALTY_BUY',
    LOYALTY_CREDIT = 'LOYALTY_CREDIT',
    LOYALTY_REDEEM = 'LOYALTY_REDEEM',
    LOYALTY_BUY_CANCEL = 'LOYALTY_BUY_CANCEL',
    LOYALTY_REDEEM_CANCEL = 'LOYALTY_REDEEM_CANCEL',
    PAYOUT = 'PAYOUT',
    PAYMENT = 'PAYMENT',
    DISPUTE = 'DISPUTE'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiTransactionDetailsCryptoCurrencySourceEnum {
    PARTNER = 'PARTNER',
    PARTY = 'PARTY'
}

/**
 * 
 * @export
 * @interface AssetValueByCategory
 */
export interface AssetValueByCategory {
    /**
     * 
     * @type {string}
     * @memberof AssetValueByCategory
     */
    'category'?: string;
    /**
     * 
     * @type {ServiceAmount}
     * @memberof AssetValueByCategory
     */
    'value'?: ServiceAmount;
}
/**
 * 
 * @export
 * @interface AttachmentDetails
 */
export interface AttachmentDetails {
    /**
     * 
     * @type {string}
     * @memberof AttachmentDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDetails
     */
    'attachmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDetails
     */
    'tokenRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDetails
     */
    'contentUrl'?: string;
}
/**
 * 
 * @export
 * @interface CreatePartnerOwnerRequest
 */
export interface CreatePartnerOwnerRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePartnerOwnerRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartnerOwnerRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartnerOwnerRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePartnerOwnerRequest
     */
    'partnerIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateRoleRequest
 */
export interface CreateRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRoleRequest
     */
    'roleName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleRequest
     */
    'roleDescription': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRoleRequest
     */
    'permissions'?: Array<CreateRoleRequestPermissionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleRequest
     */
    'partnerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleRequest
     */
    'roleType': CreateRoleRequestRoleTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateRoleRequestPermissionsEnum {
    ADMIN_CREATE = 'ADMIN_CREATE',
    ADMIN_UPDATE = 'ADMIN_UPDATE',
    CONSUMER_VIEW = 'CONSUMER_VIEW',
    CONSUMER_VIEW_DETAIL = 'CONSUMER_VIEW_DETAIL',
    USERS_CREATE = 'USERS_CREATE',
    USERS_UPDATE = 'USERS_UPDATE',
    USERS_UPDATE_SYNC = 'USERS_UPDATE_SYNC',
    USERS_VIEW = 'USERS_VIEW',
    USERS_VIEW_DETAIL = 'USERS_VIEW_DETAIL',
    ROLE_CREATE_UPDATE = 'ROLE_CREATE_UPDATE',
    ROLE_VIEW = 'ROLE_VIEW',
    TRANSACTION_VIEW = 'TRANSACTION_VIEW',
    TRANSACTION_VIEW_DETAIL = 'TRANSACTION_VIEW_DETAIL',
    TRANSACTION_CANCEL = 'TRANSACTION_CANCEL',
    TRANSACTION_REVERSE = 'TRANSACTION_REVERSE',
    PROFILE_UPDATE = 'PROFILE_UPDATE',
    PROFILE_VIEW = 'PROFILE_VIEW',
    SETTLEMENT_REPORT_VIEW = 'SETTLEMENT_REPORT_VIEW',
    SLA_REPORT_VIEW = 'SLA_REPORT_VIEW',
    DISPUTE_VIEW = 'DISPUTE_VIEW',
    DISPUTE_ACCEPT = 'DISPUTE_ACCEPT',
    DISPUTE_ADD_COMMENT = 'DISPUTE_ADD_COMMENT',
    DISPUTE_DOWNLOAD_ATTACHMENT = 'DISPUTE_DOWNLOAD_ATTACHMENT',
    DISPUTE_FILE_UPLOAD = 'DISPUTE_FILE_UPLOAD',
    DISPUTE_REJECT = 'DISPUTE_REJECT',
    DISPUTE_VIEW_COMMENT = 'DISPUTE_VIEW_COMMENT',
    TICKET_ADD_COMMENT = 'TICKET_ADD_COMMENT',
    TICKET_UPDATE_COMMENT = 'TICKET_UPDATE_COMMENT',
    TICKET_RETRIEVE_COMMENT = 'TICKET_RETRIEVE_COMMENT',
    UNMASK_ACCOUNT_NUMBER = 'UNMASK_ACCOUNT_NUMBER',
    UNMASK_ROUTING_NUMBER = 'UNMASK_ROUTING_NUMBER',
    ID_DOC_SUBMISSION_VIEW = 'ID_DOC_SUBMISSION_VIEW'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateRoleRequestRoleTypeEnum {
    BAKKT = 'BAKKT',
    PARTNER_GENERIC = 'PARTNER_GENERIC',
    PARTNER_SPECIFIC = 'PARTNER_SPECIFIC'
}

/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserRequest
     */
    'partnerIds'?: Array<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof CreateUserRequest
     */
    'roles'?: Set<string>;
}
/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'fieldName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Filter
     */
    'fieldValues'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'searchCriteria'?: FilterSearchCriteriaEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FilterSearchCriteriaEnum {
    STARTS_WITH = 'STARTS_WITH',
    ENDS_WITH = 'ENDS_WITH',
    CONTAINS = 'CONTAINS',
    NOT_CONTAINS = 'NOT_CONTAINS',
    EQUALS = 'EQUALS',
    NOT_EQUALS = 'NOT_EQUALS',
    IN = 'IN',
    BEFORE = 'BEFORE',
    BEFORE_OR_NULL = 'BEFORE_OR_NULL',
    AFTER = 'AFTER',
    AFTER_OR_NULL = 'AFTER_OR_NULL',
    BETWEEN = 'BETWEEN',
    RELATIVE_TIME = 'RELATIVE_TIME',
    LESS_THAN = 'LESS_THAN',
    LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
    LESS_THAN_OR_EQUAL_OR_NULL = 'LESS_THAN_OR_EQUAL_OR_NULL',
    GREATER_THAN = 'GREATER_THAN',
    GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
    GREATER_THAN_OR_EQUAL_OR_NULL = 'GREATER_THAN_OR_EQUAL_OR_NULL',
    IN_RANGE = 'IN_RANGE',
    NOT_NULL = 'NOT_NULL',
    NOT_IN = 'NOT_IN'
}

/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {any}
     * @memberof InlineObject
     */
    'fileToUpload': any;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {ApiPartyDashboardSearch}
     * @memberof InlineObject1
     */
    'searchRequest'?: ApiPartyDashboardSearch;
    /**
     * 
     * @type {Pageable}
     * @memberof InlineObject1
     */
    'pageable'?: Pageable;
}
/**
 * 
 * @export
 * @interface InviteUserRequest
 */
export interface InviteUserRequest {
    /**
     * 
     * @type {string}
     * @memberof InviteUserRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteUserRequest
     */
    'partnerId'?: string;
}
/**
 * 
 * @export
 * @interface MarqetaDisputeCreateRequest
 */
export interface MarqetaDisputeCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof MarqetaDisputeCreateRequest
     */
    'zendesk_ticket_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarqetaDisputeCreateRequest
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarqetaDisputeCreateRequest
     */
    'token'?: string;
    /**
     * 
     * @type {MarqetaDisputeDetails}
     * @memberof MarqetaDisputeCreateRequest
     */
    'dispute_details'?: MarqetaDisputeDetails;
}
/**
 * 
 * @export
 * @interface MarqetaDisputeDetails
 */
export interface MarqetaDisputeDetails {
    /**
     * 
     * @type {string}
     * @memberof MarqetaDisputeDetails
     */
    'original_transaction_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarqetaDisputeDetails
     */
    'original_transaction_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarqetaDisputeDetails
     */
    'dispute_amount'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarqetaDisputeDetails
     */
    'dispute_amount_change_reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarqetaDisputeDetails
     */
    'dispute_reason'?: string;
}
/**
 * 
 * @export
 * @interface PageApiPartnerPortalUser
 */
export interface PageApiPartnerPortalUser {
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerPortalUser
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerPortalUser
     */
    'totalElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerPortalUser
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerPortalUser
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerPortalUser
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ApiPartnerPortalUser>}
     * @memberof PageApiPartnerPortalUser
     */
    'content'?: Array<ApiPartnerPortalUser>;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerPortalUser
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageApiPartnerPortalUser
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerPortalUser
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageApiPartnerPortalUser
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerPortalUser
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageApiPartnerSettlementFile
 */
export interface PageApiPartnerSettlementFile {
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerSettlementFile
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerSettlementFile
     */
    'totalElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerSettlementFile
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerSettlementFile
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerSettlementFile
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ApiPartnerSettlementFile>}
     * @memberof PageApiPartnerSettlementFile
     */
    'content'?: Array<ApiPartnerSettlementFile>;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerSettlementFile
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageApiPartnerSettlementFile
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerSettlementFile
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageApiPartnerSettlementFile
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerSettlementFile
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageApiPartnerTransaction
 */
export interface PageApiPartnerTransaction {
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransaction
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransaction
     */
    'totalElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerTransaction
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerTransaction
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransaction
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ApiPartnerTransaction>}
     * @memberof PageApiPartnerTransaction
     */
    'content'?: Array<ApiPartnerTransaction>;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransaction
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageApiPartnerTransaction
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransaction
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageApiPartnerTransaction
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerTransaction
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageApiPartnerTransactionSummary
 */
export interface PageApiPartnerTransactionSummary {
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransactionSummary
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransactionSummary
     */
    'totalElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerTransactionSummary
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerTransactionSummary
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransactionSummary
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ApiPartnerTransactionSummary>}
     * @memberof PageApiPartnerTransactionSummary
     */
    'content'?: Array<ApiPartnerTransactionSummary>;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransactionSummary
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageApiPartnerTransactionSummary
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransactionSummary
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageApiPartnerTransactionSummary
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerTransactionSummary
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageApiParty
 */
export interface PageApiParty {
    /**
     * 
     * @type {number}
     * @memberof PageApiParty
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiParty
     */
    'totalElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiParty
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiParty
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiParty
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ApiParty>}
     * @memberof PageApiParty
     */
    'content'?: Array<ApiParty>;
    /**
     * 
     * @type {number}
     * @memberof PageApiParty
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageApiParty
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageApiParty
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageApiParty
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiParty
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageApiPartyBaseSearchResponse
 */
export interface PageApiPartyBaseSearchResponse {
    /**
     * 
     * @type {number}
     * @memberof PageApiPartyBaseSearchResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartyBaseSearchResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartyBaseSearchResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartyBaseSearchResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartyBaseSearchResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ApiPartyBaseSearchResponse>}
     * @memberof PageApiPartyBaseSearchResponse
     */
    'content'?: Array<ApiPartyBaseSearchResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartyBaseSearchResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageApiPartyBaseSearchResponse
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartyBaseSearchResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageApiPartyBaseSearchResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartyBaseSearchResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageApiSLAReport
 */
export interface PageApiSLAReport {
    /**
     * 
     * @type {number}
     * @memberof PageApiSLAReport
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiSLAReport
     */
    'totalElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiSLAReport
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiSLAReport
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiSLAReport
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ApiSLAReport>}
     * @memberof PageApiSLAReport
     */
    'content'?: Array<ApiSLAReport>;
    /**
     * 
     * @type {number}
     * @memberof PageApiSLAReport
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageApiSLAReport
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageApiSLAReport
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageApiSLAReport
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiSLAReport
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageableObject
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
}
/**
 * 
 * @export
 * @interface PartnerRequest
 */
export interface PartnerRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnerRequest
     */
    'partnerId'?: string;
}
/**
 * 
 * @export
 * @interface PortfolioByAsset
 */
export interface PortfolioByAsset {
    /**
     * 
     * @type {string}
     * @memberof PortfolioByAsset
     */
    'assetType'?: PortfolioByAssetAssetTypeEnum;
    /**
     * 
     * @type {ServiceAmount}
     * @memberof PortfolioByAsset
     */
    'value'?: ServiceAmount;
    /**
     * 
     * @type {Array<AssetValueByCategory>}
     * @memberof PortfolioByAsset
     */
    'assetsByCategories'?: Array<AssetValueByCategory>;
    /**
     * 
     * @type {ServiceAmount}
     * @memberof PortfolioByAsset
     */
    'availableValue'?: ServiceAmount;
}

/**
    * @export
    * @enum {string}
    */
export enum PortfolioByAssetAssetTypeEnum {
    CASH = 'CASH',
    CRYPTO = 'CRYPTO',
    REWARDS = 'REWARDS',
    GIFT_CARD = 'GIFT_CARD',
    BAKKT_CARD = 'BAKKT_CARD',
    DEBIT_CARD = 'DEBIT_CARD'
}

/**
 * 
 * @export
 * @interface ReversalRequest
 */
export interface ReversalRequest {
    /**
     * 
     * @type {string}
     * @memberof ReversalRequest
     */
    'currency'?: ReversalRequestCurrencyEnum;
    /**
     * 
     * @type {number}
     * @memberof ReversalRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReversalRequest
     */
    'merchantNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReversalRequest
     */
    'reversalReason'?: ReversalRequestReversalReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof ReversalRequest
     */
    'reversalType'?: ReversalRequestReversalTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReversalRequest
     */
    'merchantTransactionRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReversalRequest
     */
    'agent'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReversalRequest
     */
    'partnerId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReversalRequestCurrencyEnum {
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum ReversalRequestReversalReasonEnum {
    AMOUNT_EXCEEDS_AUTHORIZATION_AMOUNT = 'AMOUNT_EXCEEDS_AUTHORIZATION_AMOUNT',
    DECLINED_AUTHORIZATION = 'DECLINED_AUTHORIZATION',
    AUTHORIZATION_APPROVAL_EXPIRED = 'AUTHORIZATION_APPROVAL_EXPIRED',
    GOOD_FAITH_INVESTIGATION = 'GOOD_FAITH_INVESTIGATION',
    DOES_NOT_RECOGNIZE = 'DOES_NOT_RECOGNIZE',
    CANCELLED_RECURRING = 'CANCELLED_RECURRING',
    GOODS_NOT_FULLY_RECEIVED = 'GOODS_NOT_FULLY_RECEIVED',
    DUPLICATE_CHARGE = 'DUPLICATE_CHARGE',
    CANCELLED_MERCHANDISE = 'CANCELLED_MERCHANDISE',
    NONRECEIPT_OF_TRANSACTION_VALUE = 'NONRECEIPT_OF_TRANSACTION_VALUE'
}
/**
    * @export
    * @enum {string}
    */
export enum ReversalRequestReversalTypeEnum {
    VOID = 'VOID',
    REFUND = 'REFUND'
}

/**
 * 
 * @export
 * @interface RootPartnerLinkRequest
 */
export interface RootPartnerLinkRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RootPartnerLinkRequest
     */
    'partnerIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RootPartnerLinkRequest
     */
    'rootPartnerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RootPartnerLinkRequest
     */
    'rootPartnerName'?: string;
}
/**
 * 
 * @export
 * @interface SearchParameters
 */
export interface SearchParameters {
    /**
     * 
     * @type {number}
     * @memberof SearchParameters
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchParameters
     */
    'searchText'?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof SearchParameters
     */
    'excludeFromSearchText'?: Set<string>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters
     */
    'fromDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters
     */
    'toDate'?: number;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof SearchParameters
     */
    'filter'?: Array<Filter>;
    /**
     * 
     * @type {Array<SortBy>}
     * @memberof SearchParameters
     */
    'sort'?: Array<SortBy>;
}
/**
 * 
 * @export
 * @interface ServiceAmount
 */
export interface ServiceAmount {
    /**
     * 
     * @type {string}
     * @memberof ServiceAmount
     */
    'currency'?: ServiceAmountCurrencyEnum;
    /**
     * 
     * @type {number}
     * @memberof ServiceAmount
     */
    'amount'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ServiceAmountCurrencyEnum {
    USD = 'USD',
    AAVE = 'AAVE',
    AVAX = 'AVAX',
    AXS = 'AXS',
    BAT = 'BAT',
    BCH = 'BCH',
    BTC = 'BTC',
    DOGE = 'DOGE',
    ETH = 'ETH',
    LINK = 'LINK',
    LTC = 'LTC',
    MANA = 'MANA',
    MATIC = 'MATIC',
    SOL = 'SOL',
    UNI = 'UNI',
    USDC = 'USDC',
    LXX = 'LXX',
    LCP = 'LCP',
    LWR = 'LWR',
    LMO = 'LMO',
    LKO = 'LKO',
    LED = 'LED'
}

/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @interface SortBy
 */
export interface SortBy {
    /**
     * 
     * @type {string}
     * @memberof SortBy
     */
    'fieldName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SortBy
     */
    'sortOrder'?: SortBySortOrderEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SortBySortOrderEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}

/**
 * 
 * @export
 * @interface UpdateRoleRequest
 */
export interface UpdateRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleRequest
     */
    'roleId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleRequest
     */
    'roleDescription'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRoleRequest
     */
    'addPermissions'?: Array<UpdateRoleRequestAddPermissionsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRoleRequest
     */
    'removePermissions'?: Array<UpdateRoleRequestRemovePermissionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleRequest
     */
    'partnerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleRequest
     */
    'roleType': UpdateRoleRequestRoleTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateRoleRequestAddPermissionsEnum {
    ADMIN_CREATE = 'ADMIN_CREATE',
    ADMIN_UPDATE = 'ADMIN_UPDATE',
    CONSUMER_VIEW = 'CONSUMER_VIEW',
    CONSUMER_VIEW_DETAIL = 'CONSUMER_VIEW_DETAIL',
    USERS_CREATE = 'USERS_CREATE',
    USERS_UPDATE = 'USERS_UPDATE',
    USERS_UPDATE_SYNC = 'USERS_UPDATE_SYNC',
    USERS_VIEW = 'USERS_VIEW',
    USERS_VIEW_DETAIL = 'USERS_VIEW_DETAIL',
    ROLE_CREATE_UPDATE = 'ROLE_CREATE_UPDATE',
    ROLE_VIEW = 'ROLE_VIEW',
    TRANSACTION_VIEW = 'TRANSACTION_VIEW',
    TRANSACTION_VIEW_DETAIL = 'TRANSACTION_VIEW_DETAIL',
    TRANSACTION_CANCEL = 'TRANSACTION_CANCEL',
    TRANSACTION_REVERSE = 'TRANSACTION_REVERSE',
    PROFILE_UPDATE = 'PROFILE_UPDATE',
    PROFILE_VIEW = 'PROFILE_VIEW',
    SETTLEMENT_REPORT_VIEW = 'SETTLEMENT_REPORT_VIEW',
    SLA_REPORT_VIEW = 'SLA_REPORT_VIEW',
    DISPUTE_VIEW = 'DISPUTE_VIEW',
    DISPUTE_ACCEPT = 'DISPUTE_ACCEPT',
    DISPUTE_ADD_COMMENT = 'DISPUTE_ADD_COMMENT',
    DISPUTE_DOWNLOAD_ATTACHMENT = 'DISPUTE_DOWNLOAD_ATTACHMENT',
    DISPUTE_FILE_UPLOAD = 'DISPUTE_FILE_UPLOAD',
    DISPUTE_REJECT = 'DISPUTE_REJECT',
    DISPUTE_VIEW_COMMENT = 'DISPUTE_VIEW_COMMENT',
    TICKET_ADD_COMMENT = 'TICKET_ADD_COMMENT',
    TICKET_UPDATE_COMMENT = 'TICKET_UPDATE_COMMENT',
    TICKET_RETRIEVE_COMMENT = 'TICKET_RETRIEVE_COMMENT',
    UNMASK_ACCOUNT_NUMBER = 'UNMASK_ACCOUNT_NUMBER',
    UNMASK_ROUTING_NUMBER = 'UNMASK_ROUTING_NUMBER',
    ID_DOC_SUBMISSION_VIEW = 'ID_DOC_SUBMISSION_VIEW'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateRoleRequestRemovePermissionsEnum {
    ADMIN_CREATE = 'ADMIN_CREATE',
    ADMIN_UPDATE = 'ADMIN_UPDATE',
    CONSUMER_VIEW = 'CONSUMER_VIEW',
    CONSUMER_VIEW_DETAIL = 'CONSUMER_VIEW_DETAIL',
    USERS_CREATE = 'USERS_CREATE',
    USERS_UPDATE = 'USERS_UPDATE',
    USERS_UPDATE_SYNC = 'USERS_UPDATE_SYNC',
    USERS_VIEW = 'USERS_VIEW',
    USERS_VIEW_DETAIL = 'USERS_VIEW_DETAIL',
    ROLE_CREATE_UPDATE = 'ROLE_CREATE_UPDATE',
    ROLE_VIEW = 'ROLE_VIEW',
    TRANSACTION_VIEW = 'TRANSACTION_VIEW',
    TRANSACTION_VIEW_DETAIL = 'TRANSACTION_VIEW_DETAIL',
    TRANSACTION_CANCEL = 'TRANSACTION_CANCEL',
    TRANSACTION_REVERSE = 'TRANSACTION_REVERSE',
    PROFILE_UPDATE = 'PROFILE_UPDATE',
    PROFILE_VIEW = 'PROFILE_VIEW',
    SETTLEMENT_REPORT_VIEW = 'SETTLEMENT_REPORT_VIEW',
    SLA_REPORT_VIEW = 'SLA_REPORT_VIEW',
    DISPUTE_VIEW = 'DISPUTE_VIEW',
    DISPUTE_ACCEPT = 'DISPUTE_ACCEPT',
    DISPUTE_ADD_COMMENT = 'DISPUTE_ADD_COMMENT',
    DISPUTE_DOWNLOAD_ATTACHMENT = 'DISPUTE_DOWNLOAD_ATTACHMENT',
    DISPUTE_FILE_UPLOAD = 'DISPUTE_FILE_UPLOAD',
    DISPUTE_REJECT = 'DISPUTE_REJECT',
    DISPUTE_VIEW_COMMENT = 'DISPUTE_VIEW_COMMENT',
    TICKET_ADD_COMMENT = 'TICKET_ADD_COMMENT',
    TICKET_UPDATE_COMMENT = 'TICKET_UPDATE_COMMENT',
    TICKET_RETRIEVE_COMMENT = 'TICKET_RETRIEVE_COMMENT',
    UNMASK_ACCOUNT_NUMBER = 'UNMASK_ACCOUNT_NUMBER',
    UNMASK_ROUTING_NUMBER = 'UNMASK_ROUTING_NUMBER',
    ID_DOC_SUBMISSION_VIEW = 'ID_DOC_SUBMISSION_VIEW'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateRoleRequestRoleTypeEnum {
    BAKKT = 'BAKKT',
    PARTNER_GENERIC = 'PARTNER_GENERIC',
    PARTNER_SPECIFIC = 'PARTNER_SPECIFIC'
}

/**
 * 
 * @export
 * @interface UpdateUserPartnerRequest
 */
export interface UpdateUserPartnerRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserPartnerRequest
     */
    'addPartnerIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserPartnerRequest
     */
    'removePartnerIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserRequest
     */
    'addRoles'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserRequest
     */
    'removeRoles'?: Array<string>;
}

/**
 * DashBoardMetricsControllerApi - axios parameter creator
 * @export
 */
export const DashBoardMetricsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDashboardMetrics: async (searchParameters: SearchParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('fetchDashboardMetrics', 'searchParameters', searchParameters)
            const localVarPath = `/partner-portal/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashBoardMetricsControllerApi - functional programming interface
 * @export
 */
export const DashBoardMetricsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashBoardMetricsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchDashboardMetrics(searchParameters: SearchParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiDashBoardMetricsDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchDashboardMetrics(searchParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashBoardMetricsControllerApi - factory interface
 * @export
 */
export const DashBoardMetricsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashBoardMetricsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDashboardMetrics(searchParameters: SearchParameters, options?: any): AxiosPromise<ApiResponseApiDashBoardMetricsDetails> {
            return localVarFp.fetchDashboardMetrics(searchParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashBoardMetricsControllerApi - interface
 * @export
 * @interface DashBoardMetricsControllerApi
 */
export interface DashBoardMetricsControllerApiInterface {
    /**
     * 
     * @param {SearchParameters} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashBoardMetricsControllerApiInterface
     */
    fetchDashboardMetrics(searchParameters: SearchParameters, options?: AxiosRequestConfig): AxiosPromise<ApiResponseApiDashBoardMetricsDetails>;

}

/**
 * DashBoardMetricsControllerApi - object-oriented interface
 * @export
 * @class DashBoardMetricsControllerApi
 * @extends {BaseAPI}
 */
export class DashBoardMetricsControllerApi extends BaseAPI implements DashBoardMetricsControllerApiInterface {
    /**
     * 
     * @param {SearchParameters} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashBoardMetricsControllerApi
     */
    public fetchDashboardMetrics(searchParameters: SearchParameters, options?: AxiosRequestConfig) {
        return DashBoardMetricsControllerApiFp(this.configuration).fetchDashboardMetrics(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DisputeControllerApi - axios parameter creator
 * @export
 */
export const DisputeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} disputeId 
         * @param {ApiCommentRequest} apiCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment: async (disputeId: string, apiCommentRequest: ApiCommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'disputeId' is not null or undefined
            assertParamExists('addComment', 'disputeId', disputeId)
            // verify required parameter 'apiCommentRequest' is not null or undefined
            assertParamExists('addComment', 'apiCommentRequest', apiCommentRequest)
            const localVarPath = `/partner-portal/ticket/{disputeId}/comment`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} disputeId 
         * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveChargeBack: async (disputeId: string, apiDisputeChargebackRequest: ApiDisputeChargebackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'disputeId' is not null or undefined
            assertParamExists('approveChargeBack', 'disputeId', disputeId)
            // verify required parameter 'apiDisputeChargebackRequest' is not null or undefined
            assertParamExists('approveChargeBack', 'apiDisputeChargebackRequest', apiDisputeChargebackRequest)
            const localVarPath = `/partner-portal/dispute/{disputeId}/accept`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiDisputeChargebackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} disputeId 
         * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectCredit: async (disputeId: string, apiDisputeChargebackRequest: ApiDisputeChargebackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'disputeId' is not null or undefined
            assertParamExists('rejectCredit', 'disputeId', disputeId)
            // verify required parameter 'apiDisputeChargebackRequest' is not null or undefined
            assertParamExists('rejectCredit', 'apiDisputeChargebackRequest', apiDisputeChargebackRequest)
            const localVarPath = `/partner-portal/dispute/{disputeId}/reject`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiDisputeChargebackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiAttachmentRequest} apiAttachmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAttachment: async (apiAttachmentRequest: ApiAttachmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiAttachmentRequest' is not null or undefined
            assertParamExists('retrieveAttachment', 'apiAttachmentRequest', apiAttachmentRequest)
            const localVarPath = `/partner-portal/ticket/retrieve-attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiAttachmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAttachment: async (inlineObject?: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner-portal/ticket/upload-attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DisputeControllerApi - functional programming interface
 * @export
 */
export const DisputeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DisputeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} disputeId 
         * @param {ApiCommentRequest} apiCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addComment(disputeId: string, apiCommentRequest: ApiCommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addComment(disputeId, apiCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} disputeId 
         * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveChargeBack(disputeId: string, apiDisputeChargebackRequest: ApiDisputeChargebackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveChargeBack(disputeId, apiDisputeChargebackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} disputeId 
         * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectCredit(disputeId: string, apiDisputeChargebackRequest: ApiDisputeChargebackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectCredit(disputeId, apiDisputeChargebackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApiAttachmentRequest} apiAttachmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveAttachment(apiAttachmentRequest: ApiAttachmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveAttachment(apiAttachmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAttachment(inlineObject?: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAttachment(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DisputeControllerApi - factory interface
 * @export
 */
export const DisputeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DisputeControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} disputeId 
         * @param {ApiCommentRequest} apiCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment(disputeId: string, apiCommentRequest: ApiCommentRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.addComment(disputeId, apiCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} disputeId 
         * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveChargeBack(disputeId: string, apiDisputeChargebackRequest: ApiDisputeChargebackRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.approveChargeBack(disputeId, apiDisputeChargebackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} disputeId 
         * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectCredit(disputeId: string, apiDisputeChargebackRequest: ApiDisputeChargebackRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.rejectCredit(disputeId, apiDisputeChargebackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiAttachmentRequest} apiAttachmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAttachment(apiAttachmentRequest: ApiAttachmentRequest, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.retrieveAttachment(apiAttachmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAttachment(inlineObject?: InlineObject, options?: any): AxiosPromise<ApiResponseApiAttachment> {
            return localVarFp.uploadAttachment(inlineObject, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DisputeControllerApi - interface
 * @export
 * @interface DisputeControllerApi
 */
export interface DisputeControllerApiInterface {
    /**
     * 
     * @param {string} disputeId 
     * @param {ApiCommentRequest} apiCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisputeControllerApiInterface
     */
    addComment(disputeId: string, apiCommentRequest: ApiCommentRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseVoid>;

    /**
     * 
     * @param {string} disputeId 
     * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisputeControllerApiInterface
     */
    approveChargeBack(disputeId: string, apiDisputeChargebackRequest: ApiDisputeChargebackRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseVoid>;

    /**
     * 
     * @param {string} disputeId 
     * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisputeControllerApiInterface
     */
    rejectCredit(disputeId: string, apiDisputeChargebackRequest: ApiDisputeChargebackRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseVoid>;

    /**
     * 
     * @param {ApiAttachmentRequest} apiAttachmentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisputeControllerApiInterface
     */
    retrieveAttachment(apiAttachmentRequest: ApiAttachmentRequest, options?: AxiosRequestConfig): AxiosPromise<Array<string>>;

    /**
     * 
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisputeControllerApiInterface
     */
    uploadAttachment(inlineObject?: InlineObject, options?: AxiosRequestConfig): AxiosPromise<ApiResponseApiAttachment>;

}

/**
 * DisputeControllerApi - object-oriented interface
 * @export
 * @class DisputeControllerApi
 * @extends {BaseAPI}
 */
export class DisputeControllerApi extends BaseAPI implements DisputeControllerApiInterface {
    /**
     * 
     * @param {string} disputeId 
     * @param {ApiCommentRequest} apiCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisputeControllerApi
     */
    public addComment(disputeId: string, apiCommentRequest: ApiCommentRequest, options?: AxiosRequestConfig) {
        return DisputeControllerApiFp(this.configuration).addComment(disputeId, apiCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} disputeId 
     * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisputeControllerApi
     */
    public approveChargeBack(disputeId: string, apiDisputeChargebackRequest: ApiDisputeChargebackRequest, options?: AxiosRequestConfig) {
        return DisputeControllerApiFp(this.configuration).approveChargeBack(disputeId, apiDisputeChargebackRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} disputeId 
     * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisputeControllerApi
     */
    public rejectCredit(disputeId: string, apiDisputeChargebackRequest: ApiDisputeChargebackRequest, options?: AxiosRequestConfig) {
        return DisputeControllerApiFp(this.configuration).rejectCredit(disputeId, apiDisputeChargebackRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiAttachmentRequest} apiAttachmentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisputeControllerApi
     */
    public retrieveAttachment(apiAttachmentRequest: ApiAttachmentRequest, options?: AxiosRequestConfig) {
        return DisputeControllerApiFp(this.configuration).retrieveAttachment(apiAttachmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisputeControllerApi
     */
    public uploadAttachment(inlineObject?: InlineObject, options?: AxiosRequestConfig) {
        return DisputeControllerApiFp(this.configuration).uploadAttachment(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerControllerApi - axios parameter creator
 * @export
 */
export const PartnerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MarqetaDisputeCreateRequest} marqetaDisputeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disputeMarqetaTransaction: async (marqetaDisputeCreateRequest: MarqetaDisputeCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marqetaDisputeCreateRequest' is not null or undefined
            assertParamExists('disputeMarqetaTransaction', 'marqetaDisputeCreateRequest', marqetaDisputeCreateRequest)
            const localVarPath = `/partner-portal/marqeta/dispute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marqetaDisputeCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PartnerRequest} partnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerChildren: async (partnerRequest: PartnerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerRequest' is not null or undefined
            assertParamExists('fetchPartnerChildren', 'partnerRequest', partnerRequest)
            const localVarPath = `/partner-portal/partner/child`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partnerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PartnerRequest} partnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerDescendants: async (partnerRequest: PartnerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerRequest' is not null or undefined
            assertParamExists('fetchPartnerDescendants', 'partnerRequest', partnerRequest)
            const localVarPath = `/partner-portal/partner/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partnerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PartnerRequest} partnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerDetails: async (partnerRequest: PartnerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerRequest' is not null or undefined
            assertParamExists('fetchPartnerDetails', 'partnerRequest', partnerRequest)
            const localVarPath = `/partner-portal/partner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partnerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerSpreadAndCommission: async (partnerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('fetchPartnerSpreadAndCommission', 'partnerId', partnerId)
            const localVarPath = `/partner-portal/partner/spread-and-commission/{partnerId}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} originalTransactionToken 
         * @param {string} [chargebackToken] 
         * @param {string} [userToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisputeMarqetaTransaction: async (originalTransactionToken: string, chargebackToken?: string, userToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'originalTransactionToken' is not null or undefined
            assertParamExists('getDisputeMarqetaTransaction', 'originalTransactionToken', originalTransactionToken)
            const localVarPath = `/partner-portal/marqeta/dispute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (chargebackToken !== undefined) {
                localVarQueryParameter['chargeback_token'] = chargebackToken;
            }

            if (originalTransactionToken !== undefined) {
                localVarQueryParameter['original_transaction_token'] = originalTransactionToken;
            }

            if (userToken !== undefined) {
                localVarQueryParameter['user_token'] = userToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RootPartnerLinkRequest} rootPartnerLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkPartnersToRootPartner: async (rootPartnerLinkRequest: RootPartnerLinkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rootPartnerLinkRequest' is not null or undefined
            assertParamExists('linkPartnersToRootPartner', 'rootPartnerLinkRequest', rootPartnerLinkRequest)
            const localVarPath = `/partner-portal/partner/link-partner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rootPartnerLinkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskAccountNumber: async (partnerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('unmaskAccountNumber', 'partnerId', partnerId)
            const localVarPath = `/partner-portal/partner/{partnerId}/accountNumber/unmask`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskRoutingNumber: async (partnerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('unmaskRoutingNumber', 'partnerId', partnerId)
            const localVarPath = `/partner-portal/partner/{partnerId}/routingNumber/unmask`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerControllerApi - functional programming interface
 * @export
 */
export const PartnerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MarqetaDisputeCreateRequest} marqetaDisputeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disputeMarqetaTransaction(marqetaDisputeCreateRequest: MarqetaDisputeCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiMarqetaDisputeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disputeMarqetaTransaction(marqetaDisputeCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PartnerRequest} partnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPartnerChildren(partnerRequest: PartnerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListApiPartnerSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPartnerChildren(partnerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PartnerRequest} partnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPartnerDescendants(partnerRequest: PartnerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListApiPartnerSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPartnerDescendants(partnerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PartnerRequest} partnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPartnerDetails(partnerRequest: PartnerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiPartner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPartnerDetails(partnerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPartnerSpreadAndCommission(partnerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiPartnerSpreadAndCommission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPartnerSpreadAndCommission(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} originalTransactionToken 
         * @param {string} [chargebackToken] 
         * @param {string} [userToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDisputeMarqetaTransaction(originalTransactionToken: string, chargebackToken?: string, userToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListApiMarqetaDisputeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDisputeMarqetaTransaction(originalTransactionToken, chargebackToken, userToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RootPartnerLinkRequest} rootPartnerLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkPartnersToRootPartner(rootPartnerLinkRequest: RootPartnerLinkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkPartnersToRootPartner(rootPartnerLinkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unmaskAccountNumber(partnerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unmaskAccountNumber(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unmaskRoutingNumber(partnerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unmaskRoutingNumber(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerControllerApi - factory interface
 * @export
 */
export const PartnerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {MarqetaDisputeCreateRequest} marqetaDisputeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disputeMarqetaTransaction(marqetaDisputeCreateRequest: MarqetaDisputeCreateRequest, options?: any): AxiosPromise<ApiResponseApiMarqetaDisputeResponse> {
            return localVarFp.disputeMarqetaTransaction(marqetaDisputeCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnerRequest} partnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerChildren(partnerRequest: PartnerRequest, options?: any): AxiosPromise<ApiResponseListApiPartnerSummary> {
            return localVarFp.fetchPartnerChildren(partnerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnerRequest} partnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerDescendants(partnerRequest: PartnerRequest, options?: any): AxiosPromise<ApiResponseListApiPartnerSummary> {
            return localVarFp.fetchPartnerDescendants(partnerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnerRequest} partnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerDetails(partnerRequest: PartnerRequest, options?: any): AxiosPromise<ApiResponseApiPartner> {
            return localVarFp.fetchPartnerDetails(partnerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerSpreadAndCommission(partnerId: string, options?: any): AxiosPromise<ApiResponseApiPartnerSpreadAndCommission> {
            return localVarFp.fetchPartnerSpreadAndCommission(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} originalTransactionToken 
         * @param {string} [chargebackToken] 
         * @param {string} [userToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisputeMarqetaTransaction(originalTransactionToken: string, chargebackToken?: string, userToken?: string, options?: any): AxiosPromise<ApiResponseListApiMarqetaDisputeResponse> {
            return localVarFp.getDisputeMarqetaTransaction(originalTransactionToken, chargebackToken, userToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RootPartnerLinkRequest} rootPartnerLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkPartnersToRootPartner(rootPartnerLinkRequest: RootPartnerLinkRequest, options?: any): AxiosPromise<ApiResponseString> {
            return localVarFp.linkPartnersToRootPartner(rootPartnerLinkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskAccountNumber(partnerId: string, options?: any): AxiosPromise<ApiResponseString> {
            return localVarFp.unmaskAccountNumber(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskRoutingNumber(partnerId: string, options?: any): AxiosPromise<ApiResponseString> {
            return localVarFp.unmaskRoutingNumber(partnerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerControllerApi - interface
 * @export
 * @interface PartnerControllerApi
 */
export interface PartnerControllerApiInterface {
    /**
     * 
     * @param {MarqetaDisputeCreateRequest} marqetaDisputeCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApiInterface
     */
    disputeMarqetaTransaction(marqetaDisputeCreateRequest: MarqetaDisputeCreateRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseApiMarqetaDisputeResponse>;

    /**
     * 
     * @param {PartnerRequest} partnerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApiInterface
     */
    fetchPartnerChildren(partnerRequest: PartnerRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseListApiPartnerSummary>;

    /**
     * 
     * @param {PartnerRequest} partnerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApiInterface
     */
    fetchPartnerDescendants(partnerRequest: PartnerRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseListApiPartnerSummary>;

    /**
     * 
     * @param {PartnerRequest} partnerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApiInterface
     */
    fetchPartnerDetails(partnerRequest: PartnerRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseApiPartner>;

    /**
     * 
     * @param {string} partnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApiInterface
     */
    fetchPartnerSpreadAndCommission(partnerId: string, options?: AxiosRequestConfig): AxiosPromise<ApiResponseApiPartnerSpreadAndCommission>;

    /**
     * 
     * @param {string} originalTransactionToken 
     * @param {string} [chargebackToken] 
     * @param {string} [userToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApiInterface
     */
    getDisputeMarqetaTransaction(originalTransactionToken: string, chargebackToken?: string, userToken?: string, options?: AxiosRequestConfig): AxiosPromise<ApiResponseListApiMarqetaDisputeResponse>;

    /**
     * 
     * @param {RootPartnerLinkRequest} rootPartnerLinkRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApiInterface
     */
    linkPartnersToRootPartner(rootPartnerLinkRequest: RootPartnerLinkRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseString>;

    /**
     * 
     * @param {string} partnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApiInterface
     */
    unmaskAccountNumber(partnerId: string, options?: AxiosRequestConfig): AxiosPromise<ApiResponseString>;

    /**
     * 
     * @param {string} partnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApiInterface
     */
    unmaskRoutingNumber(partnerId: string, options?: AxiosRequestConfig): AxiosPromise<ApiResponseString>;

}

/**
 * PartnerControllerApi - object-oriented interface
 * @export
 * @class PartnerControllerApi
 * @extends {BaseAPI}
 */
export class PartnerControllerApi extends BaseAPI implements PartnerControllerApiInterface {
    /**
     * 
     * @param {MarqetaDisputeCreateRequest} marqetaDisputeCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApi
     */
    public disputeMarqetaTransaction(marqetaDisputeCreateRequest: MarqetaDisputeCreateRequest, options?: AxiosRequestConfig) {
        return PartnerControllerApiFp(this.configuration).disputeMarqetaTransaction(marqetaDisputeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnerRequest} partnerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApi
     */
    public fetchPartnerChildren(partnerRequest: PartnerRequest, options?: AxiosRequestConfig) {
        return PartnerControllerApiFp(this.configuration).fetchPartnerChildren(partnerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnerRequest} partnerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApi
     */
    public fetchPartnerDescendants(partnerRequest: PartnerRequest, options?: AxiosRequestConfig) {
        return PartnerControllerApiFp(this.configuration).fetchPartnerDescendants(partnerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnerRequest} partnerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApi
     */
    public fetchPartnerDetails(partnerRequest: PartnerRequest, options?: AxiosRequestConfig) {
        return PartnerControllerApiFp(this.configuration).fetchPartnerDetails(partnerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} partnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApi
     */
    public fetchPartnerSpreadAndCommission(partnerId: string, options?: AxiosRequestConfig) {
        return PartnerControllerApiFp(this.configuration).fetchPartnerSpreadAndCommission(partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} originalTransactionToken 
     * @param {string} [chargebackToken] 
     * @param {string} [userToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApi
     */
    public getDisputeMarqetaTransaction(originalTransactionToken: string, chargebackToken?: string, userToken?: string, options?: AxiosRequestConfig) {
        return PartnerControllerApiFp(this.configuration).getDisputeMarqetaTransaction(originalTransactionToken, chargebackToken, userToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RootPartnerLinkRequest} rootPartnerLinkRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApi
     */
    public linkPartnersToRootPartner(rootPartnerLinkRequest: RootPartnerLinkRequest, options?: AxiosRequestConfig) {
        return PartnerControllerApiFp(this.configuration).linkPartnersToRootPartner(rootPartnerLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} partnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApi
     */
    public unmaskAccountNumber(partnerId: string, options?: AxiosRequestConfig) {
        return PartnerControllerApiFp(this.configuration).unmaskAccountNumber(partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} partnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApi
     */
    public unmaskRoutingNumber(partnerId: string, options?: AxiosRequestConfig) {
        return PartnerControllerApiFp(this.configuration).unmaskRoutingNumber(partnerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerSettlementFileControllerApi - axios parameter creator
 * @export
 */
export const PartnerSettlementFileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerSettlementFile: async (searchParameters: SearchParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('fetchPartnerSettlementFile', 'searchParameters', searchParameters)
            const localVarPath = `/partner-portal/partner/settlement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiPartnerSettlementFile} apiPartnerSettlementFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerSettlementReport: async (apiPartnerSettlementFile: ApiPartnerSettlementFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiPartnerSettlementFile' is not null or undefined
            assertParamExists('getPartnerSettlementReport', 'apiPartnerSettlementFile', apiPartnerSettlementFile)
            const localVarPath = `/partner-portal/partner/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartnerSettlementFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerSettlementFileControllerApi - functional programming interface
 * @export
 */
export const PartnerSettlementFileControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerSettlementFileControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPartnerSettlementFile(searchParameters: SearchParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiPartnerSettlementFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPartnerSettlementFile(searchParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApiPartnerSettlementFile} apiPartnerSettlementFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnerSettlementReport(apiPartnerSettlementFile: ApiPartnerSettlementFile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseURL>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnerSettlementReport(apiPartnerSettlementFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerSettlementFileControllerApi - factory interface
 * @export
 */
export const PartnerSettlementFileControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerSettlementFileControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerSettlementFile(searchParameters: SearchParameters, options?: any): AxiosPromise<ApiResponsePageApiPartnerSettlementFile> {
            return localVarFp.fetchPartnerSettlementFile(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiPartnerSettlementFile} apiPartnerSettlementFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerSettlementReport(apiPartnerSettlementFile: ApiPartnerSettlementFile, options?: any): AxiosPromise<ApiResponseURL> {
            return localVarFp.getPartnerSettlementReport(apiPartnerSettlementFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerSettlementFileControllerApi - interface
 * @export
 * @interface PartnerSettlementFileControllerApi
 */
export interface PartnerSettlementFileControllerApiInterface {
    /**
     * 
     * @param {SearchParameters} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerSettlementFileControllerApiInterface
     */
    fetchPartnerSettlementFile(searchParameters: SearchParameters, options?: AxiosRequestConfig): AxiosPromise<ApiResponsePageApiPartnerSettlementFile>;

    /**
     * 
     * @param {ApiPartnerSettlementFile} apiPartnerSettlementFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerSettlementFileControllerApiInterface
     */
    getPartnerSettlementReport(apiPartnerSettlementFile: ApiPartnerSettlementFile, options?: AxiosRequestConfig): AxiosPromise<ApiResponseURL>;

}

/**
 * PartnerSettlementFileControllerApi - object-oriented interface
 * @export
 * @class PartnerSettlementFileControllerApi
 * @extends {BaseAPI}
 */
export class PartnerSettlementFileControllerApi extends BaseAPI implements PartnerSettlementFileControllerApiInterface {
    /**
     * 
     * @param {SearchParameters} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerSettlementFileControllerApi
     */
    public fetchPartnerSettlementFile(searchParameters: SearchParameters, options?: AxiosRequestConfig) {
        return PartnerSettlementFileControllerApiFp(this.configuration).fetchPartnerSettlementFile(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiPartnerSettlementFile} apiPartnerSettlementFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerSettlementFileControllerApi
     */
    public getPartnerSettlementReport(apiPartnerSettlementFile: ApiPartnerSettlementFile, options?: AxiosRequestConfig) {
        return PartnerSettlementFileControllerApiFp(this.configuration).getPartnerSettlementReport(apiPartnerSettlementFile, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerTransactionControllerApi - axios parameter creator
 * @export
 */
export const PartnerTransactionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} transactionId 
         * @param {ReversalRequest} reversalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransaction: async (transactionId: string, reversalRequest: ReversalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('cancelTransaction', 'transactionId', transactionId)
            // verify required parameter 'reversalRequest' is not null or undefined
            assertParamExists('cancelTransaction', 'reversalRequest', reversalRequest)
            const localVarPath = `/partner-portal/transactions/{transactionId}/cancel`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reversalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partnerId 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerSettlementTransactions: async (partnerId: string, searchParameters: SearchParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('fetchPartnerSettlementTransactions', 'partnerId', partnerId)
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('fetchPartnerSettlementTransactions', 'searchParameters', searchParameters)
            const localVarPath = `/partner-portal/settlement/{partnerId}/transactions`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartyPartnerTransaction: async (searchParameters: SearchParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('fetchPartyPartnerTransaction', 'searchParameters', searchParameters)
            const localVarPath = `/partner-portal/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTransactionDetailsById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchTransactionDetailsById', 'id', id)
            const localVarPath = `/partner-portal/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {ReversalRequest} reversalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reverseTransaction: async (transactionId: string, reversalRequest: ReversalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('reverseTransaction', 'transactionId', transactionId)
            // verify required parameter 'reversalRequest' is not null or undefined
            assertParamExists('reverseTransaction', 'reversalRequest', reversalRequest)
            const localVarPath = `/partner-portal/transactions/{transactionId}/reverse`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reversalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partyId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartyTransactions: async (partyId: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('searchPartyTransactions', 'partyId', partyId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('searchPartyTransactions', 'pageable', pageable)
            const localVarPath = `/partner-portal/transactions/party/{partyId}`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerTransactionControllerApi - functional programming interface
 * @export
 */
export const PartnerTransactionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerTransactionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} transactionId 
         * @param {ReversalRequest} reversalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelTransaction(transactionId: string, reversalRequest: ReversalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelTransaction(transactionId, reversalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} partnerId 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPartnerSettlementTransactions(partnerId: string, searchParameters: SearchParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiPartnerTransactionSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPartnerSettlementTransactions(partnerId, searchParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPartyPartnerTransaction(searchParameters: SearchParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiPartnerTransactionSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPartyPartnerTransaction(searchParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchTransactionDetailsById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiTransactionDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchTransactionDetailsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {ReversalRequest} reversalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reverseTransaction(transactionId: string, reversalRequest: ReversalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reverseTransaction(transactionId, reversalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} partyId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPartyTransactions(partyId: string, pageable: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiPartnerTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPartyTransactions(partyId, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerTransactionControllerApi - factory interface
 * @export
 */
export const PartnerTransactionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerTransactionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} transactionId 
         * @param {ReversalRequest} reversalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransaction(transactionId: string, reversalRequest: ReversalRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.cancelTransaction(transactionId, reversalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} partnerId 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerSettlementTransactions(partnerId: string, searchParameters: SearchParameters, options?: any): AxiosPromise<ApiResponsePageApiPartnerTransactionSummary> {
            return localVarFp.fetchPartnerSettlementTransactions(partnerId, searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartyPartnerTransaction(searchParameters: SearchParameters, options?: any): AxiosPromise<ApiResponsePageApiPartnerTransactionSummary> {
            return localVarFp.fetchPartyPartnerTransaction(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTransactionDetailsById(id: string, options?: any): AxiosPromise<ApiResponseApiTransactionDetails> {
            return localVarFp.fetchTransactionDetailsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {ReversalRequest} reversalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reverseTransaction(transactionId: string, reversalRequest: ReversalRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.reverseTransaction(transactionId, reversalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} partyId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartyTransactions(partyId: string, pageable: Pageable, options?: any): AxiosPromise<ApiResponsePageApiPartnerTransaction> {
            return localVarFp.searchPartyTransactions(partyId, pageable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerTransactionControllerApi - interface
 * @export
 * @interface PartnerTransactionControllerApi
 */
export interface PartnerTransactionControllerApiInterface {
    /**
     * 
     * @param {string} transactionId 
     * @param {ReversalRequest} reversalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionControllerApiInterface
     */
    cancelTransaction(transactionId: string, reversalRequest: ReversalRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseVoid>;

    /**
     * 
     * @param {string} partnerId 
     * @param {SearchParameters} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionControllerApiInterface
     */
    fetchPartnerSettlementTransactions(partnerId: string, searchParameters: SearchParameters, options?: AxiosRequestConfig): AxiosPromise<ApiResponsePageApiPartnerTransactionSummary>;

    /**
     * 
     * @param {SearchParameters} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionControllerApiInterface
     */
    fetchPartyPartnerTransaction(searchParameters: SearchParameters, options?: AxiosRequestConfig): AxiosPromise<ApiResponsePageApiPartnerTransactionSummary>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionControllerApiInterface
     */
    fetchTransactionDetailsById(id: string, options?: AxiosRequestConfig): AxiosPromise<ApiResponseApiTransactionDetails>;

    /**
     * 
     * @param {string} transactionId 
     * @param {ReversalRequest} reversalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionControllerApiInterface
     */
    reverseTransaction(transactionId: string, reversalRequest: ReversalRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseVoid>;

    /**
     * 
     * @param {string} partyId 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionControllerApiInterface
     */
    searchPartyTransactions(partyId: string, pageable: Pageable, options?: AxiosRequestConfig): AxiosPromise<ApiResponsePageApiPartnerTransaction>;

}

/**
 * PartnerTransactionControllerApi - object-oriented interface
 * @export
 * @class PartnerTransactionControllerApi
 * @extends {BaseAPI}
 */
export class PartnerTransactionControllerApi extends BaseAPI implements PartnerTransactionControllerApiInterface {
    /**
     * 
     * @param {string} transactionId 
     * @param {ReversalRequest} reversalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionControllerApi
     */
    public cancelTransaction(transactionId: string, reversalRequest: ReversalRequest, options?: AxiosRequestConfig) {
        return PartnerTransactionControllerApiFp(this.configuration).cancelTransaction(transactionId, reversalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} partnerId 
     * @param {SearchParameters} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionControllerApi
     */
    public fetchPartnerSettlementTransactions(partnerId: string, searchParameters: SearchParameters, options?: AxiosRequestConfig) {
        return PartnerTransactionControllerApiFp(this.configuration).fetchPartnerSettlementTransactions(partnerId, searchParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchParameters} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionControllerApi
     */
    public fetchPartyPartnerTransaction(searchParameters: SearchParameters, options?: AxiosRequestConfig) {
        return PartnerTransactionControllerApiFp(this.configuration).fetchPartyPartnerTransaction(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionControllerApi
     */
    public fetchTransactionDetailsById(id: string, options?: AxiosRequestConfig) {
        return PartnerTransactionControllerApiFp(this.configuration).fetchTransactionDetailsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} transactionId 
     * @param {ReversalRequest} reversalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionControllerApi
     */
    public reverseTransaction(transactionId: string, reversalRequest: ReversalRequest, options?: AxiosRequestConfig) {
        return PartnerTransactionControllerApiFp(this.configuration).reverseTransaction(transactionId, reversalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} partyId 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionControllerApi
     */
    public searchPartyTransactions(partyId: string, pageable: Pageable, options?: AxiosRequestConfig) {
        return PartnerTransactionControllerApiFp(this.configuration).searchPartyTransactions(partyId, pageable, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerUserRoleControllerApi - axios parameter creator
 * @export
 */
export const PartnerUserRoleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearRoleCaches: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner-portal/role/clear-cache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBakktUser: async (createUserRequest: CreateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('createBakktUser', 'createUserRequest', createUserRequest)
            const localVarPath = `/partner-portal/users/tango-portal/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateUser: async (createUserRequest: CreateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('createOrUpdateUser', 'createUserRequest', createUserRequest)
            const localVarPath = `/partner-portal/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreatePartnerOwnerRequest} createPartnerOwnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartnerOwner: async (createPartnerOwnerRequest: CreatePartnerOwnerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPartnerOwnerRequest' is not null or undefined
            assertParamExists('createPartnerOwner', 'createPartnerOwnerRequest', createPartnerOwnerRequest)
            const localVarPath = `/partner-portal/users/partner/owner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPartnerOwnerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateRoleRequest} createRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleWithPermissions: async (createRoleRequest: CreateRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRoleRequest' is not null or undefined
            assertParamExists('createRoleWithPermissions', 'createRoleRequest', createRoleRequest)
            const localVarPath = `/partner-portal/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fusionAuthRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePartnerUser: async (fusionAuthRef: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fusionAuthRef' is not null or undefined
            assertParamExists('deactivatePartnerUser', 'fusionAuthRef', fusionAuthRef)
            const localVarPath = `/partner-portal/users/{fusionAuthRef}/deactivate`
                .replace(`{${"fusionAuthRef"}}`, encodeURIComponent(String(fusionAuthRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerUserSessionInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner-portal/users/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fusionAuthRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUserDetail: async (fusionAuthRef: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fusionAuthRef' is not null or undefined
            assertParamExists('getPortalUserDetail', 'fusionAuthRef', fusionAuthRef)
            const localVarPath = `/partner-portal/users/{fusionAuthRef}`
                .replace(`{${"fusionAuthRef"}}`, encodeURIComponent(String(fusionAuthRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUsers: async (searchParameters: SearchParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('getPortalUsers', 'searchParameters', searchParameters)
            const localVarPath = `/partner-portal/users/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner-portal/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fusionAuthRef 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUserRoles: async (fusionAuthRef: string, updateUserRequest: UpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fusionAuthRef' is not null or undefined
            assertParamExists('modifyUserRoles', 'fusionAuthRef', fusionAuthRef)
            // verify required parameter 'updateUserRequest' is not null or undefined
            assertParamExists('modifyUserRoles', 'updateUserRequest', updateUserRequest)
            const localVarPath = `/partner-portal/users/{fusionAuthRef}/roles`
                .replace(`{${"fusionAuthRef"}}`, encodeURIComponent(String(fusionAuthRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fusionAuthRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivatePartnerUser: async (fusionAuthRef: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fusionAuthRef' is not null or undefined
            assertParamExists('reactivatePartnerUser', 'fusionAuthRef', fusionAuthRef)
            const localVarPath = `/partner-portal/users/{fusionAuthRef}/reactivate`
                .replace(`{${"fusionAuthRef"}}`, encodeURIComponent(String(fusionAuthRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InviteUserRequest} inviteUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvite: async (inviteUserRequest: InviteUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteUserRequest' is not null or undefined
            assertParamExists('sendInvite', 'inviteUserRequest', inviteUserRequest)
            const localVarPath = `/partner-portal/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole: async (updateRoleRequest: UpdateRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateRoleRequest' is not null or undefined
            assertParamExists('updateRole', 'updateRoleRequest', updateRoleRequest)
            const localVarPath = `/partner-portal/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fusionAuthRef 
         * @param {UpdateUserPartnerRequest} updateUserPartnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPartner: async (fusionAuthRef: string, updateUserPartnerRequest: UpdateUserPartnerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fusionAuthRef' is not null or undefined
            assertParamExists('updateUserPartner', 'fusionAuthRef', fusionAuthRef)
            // verify required parameter 'updateUserPartnerRequest' is not null or undefined
            assertParamExists('updateUserPartner', 'updateUserPartnerRequest', updateUserPartnerRequest)
            const localVarPath = `/partner-portal/users/{fusionAuthRef}/partner`
                .replace(`{${"fusionAuthRef"}}`, encodeURIComponent(String(fusionAuthRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserPartnerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerUserRoleControllerApi - functional programming interface
 * @export
 */
export const PartnerUserRoleControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerUserRoleControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearRoleCaches(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearRoleCaches(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBakktUser(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBakktUser(createUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateUser(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateUser(createUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreatePartnerOwnerRequest} createPartnerOwnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPartnerOwner(createPartnerOwnerRequest: CreatePartnerOwnerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPartnerOwner(createPartnerOwnerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateRoleRequest} createRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoleWithPermissions(createRoleRequest: CreateRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseUUID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoleWithPermissions(createRoleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fusionAuthRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivatePartnerUser(fusionAuthRef: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivatePartnerUser(fusionAuthRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnerUserSessionInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPartnerUserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnerUserSessionInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fusionAuthRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortalUserDetail(fusionAuthRef: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiPartnerPortalUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortalUserDetail(fusionAuthRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortalUsers(searchParameters: SearchParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiPartnerPortalUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortalUsers(searchParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListApiPortalRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoleList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fusionAuthRef 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyUserRoles(fusionAuthRef: string, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyUserRoles(fusionAuthRef, updateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fusionAuthRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactivatePartnerUser(fusionAuthRef: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactivatePartnerUser(fusionAuthRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InviteUserRequest} inviteUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInvite(inviteUserRequest: InviteUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInvite(inviteUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRole(updateRoleRequest: UpdateRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseUUID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRole(updateRoleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fusionAuthRef 
         * @param {UpdateUserPartnerRequest} updateUserPartnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPartner(fusionAuthRef: string, updateUserPartnerRequest: UpdateUserPartnerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPartner(fusionAuthRef, updateUserPartnerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerUserRoleControllerApi - factory interface
 * @export
 */
export const PartnerUserRoleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerUserRoleControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearRoleCaches(options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.clearRoleCaches(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBakktUser(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.createBakktUser(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateUser(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.createOrUpdateUser(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreatePartnerOwnerRequest} createPartnerOwnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartnerOwner(createPartnerOwnerRequest: CreatePartnerOwnerRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.createPartnerOwner(createPartnerOwnerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateRoleRequest} createRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleWithPermissions(createRoleRequest: CreateRoleRequest, options?: any): AxiosPromise<ApiResponseUUID> {
            return localVarFp.createRoleWithPermissions(createRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fusionAuthRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePartnerUser(fusionAuthRef: string, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.deactivatePartnerUser(fusionAuthRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerUserSessionInfo(options?: any): AxiosPromise<ApiPartnerUserInfo> {
            return localVarFp.getPartnerUserSessionInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fusionAuthRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUserDetail(fusionAuthRef: string, options?: any): AxiosPromise<ApiResponseApiPartnerPortalUser> {
            return localVarFp.getPortalUserDetail(fusionAuthRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUsers(searchParameters: SearchParameters, options?: any): AxiosPromise<ApiResponsePageApiPartnerPortalUser> {
            return localVarFp.getPortalUsers(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleList(options?: any): AxiosPromise<ApiResponseListApiPortalRole> {
            return localVarFp.getRoleList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fusionAuthRef 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUserRoles(fusionAuthRef: string, updateUserRequest: UpdateUserRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.modifyUserRoles(fusionAuthRef, updateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fusionAuthRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivatePartnerUser(fusionAuthRef: string, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.reactivatePartnerUser(fusionAuthRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InviteUserRequest} inviteUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvite(inviteUserRequest: InviteUserRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.sendInvite(inviteUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole(updateRoleRequest: UpdateRoleRequest, options?: any): AxiosPromise<ApiResponseUUID> {
            return localVarFp.updateRole(updateRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fusionAuthRef 
         * @param {UpdateUserPartnerRequest} updateUserPartnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPartner(fusionAuthRef: string, updateUserPartnerRequest: UpdateUserPartnerRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.updateUserPartner(fusionAuthRef, updateUserPartnerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerUserRoleControllerApi - interface
 * @export
 * @interface PartnerUserRoleControllerApi
 */
export interface PartnerUserRoleControllerApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApiInterface
     */
    clearRoleCaches(options?: AxiosRequestConfig): AxiosPromise<ApiResponseVoid>;

    /**
     * 
     * @param {CreateUserRequest} createUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApiInterface
     */
    createBakktUser(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseVoid>;

    /**
     * 
     * @param {CreateUserRequest} createUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApiInterface
     */
    createOrUpdateUser(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseVoid>;

    /**
     * 
     * @param {CreatePartnerOwnerRequest} createPartnerOwnerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApiInterface
     */
    createPartnerOwner(createPartnerOwnerRequest: CreatePartnerOwnerRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseVoid>;

    /**
     * 
     * @param {CreateRoleRequest} createRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApiInterface
     */
    createRoleWithPermissions(createRoleRequest: CreateRoleRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseUUID>;

    /**
     * 
     * @param {string} fusionAuthRef 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApiInterface
     */
    deactivatePartnerUser(fusionAuthRef: string, options?: AxiosRequestConfig): AxiosPromise<ApiResponseVoid>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApiInterface
     */
    getPartnerUserSessionInfo(options?: AxiosRequestConfig): AxiosPromise<ApiPartnerUserInfo>;

    /**
     * 
     * @param {string} fusionAuthRef 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApiInterface
     */
    getPortalUserDetail(fusionAuthRef: string, options?: AxiosRequestConfig): AxiosPromise<ApiResponseApiPartnerPortalUser>;

    /**
     * 
     * @param {SearchParameters} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApiInterface
     */
    getPortalUsers(searchParameters: SearchParameters, options?: AxiosRequestConfig): AxiosPromise<ApiResponsePageApiPartnerPortalUser>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApiInterface
     */
    getRoleList(options?: AxiosRequestConfig): AxiosPromise<ApiResponseListApiPortalRole>;

    /**
     * 
     * @param {string} fusionAuthRef 
     * @param {UpdateUserRequest} updateUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApiInterface
     */
    modifyUserRoles(fusionAuthRef: string, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseVoid>;

    /**
     * 
     * @param {string} fusionAuthRef 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApiInterface
     */
    reactivatePartnerUser(fusionAuthRef: string, options?: AxiosRequestConfig): AxiosPromise<ApiResponseVoid>;

    /**
     * 
     * @param {InviteUserRequest} inviteUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApiInterface
     */
    sendInvite(inviteUserRequest: InviteUserRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseVoid>;

    /**
     * 
     * @param {UpdateRoleRequest} updateRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApiInterface
     */
    updateRole(updateRoleRequest: UpdateRoleRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseUUID>;

    /**
     * 
     * @param {string} fusionAuthRef 
     * @param {UpdateUserPartnerRequest} updateUserPartnerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApiInterface
     */
    updateUserPartner(fusionAuthRef: string, updateUserPartnerRequest: UpdateUserPartnerRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseVoid>;

}

/**
 * PartnerUserRoleControllerApi - object-oriented interface
 * @export
 * @class PartnerUserRoleControllerApi
 * @extends {BaseAPI}
 */
export class PartnerUserRoleControllerApi extends BaseAPI implements PartnerUserRoleControllerApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public clearRoleCaches(options?: AxiosRequestConfig) {
        return PartnerUserRoleControllerApiFp(this.configuration).clearRoleCaches(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateUserRequest} createUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public createBakktUser(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig) {
        return PartnerUserRoleControllerApiFp(this.configuration).createBakktUser(createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateUserRequest} createUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public createOrUpdateUser(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig) {
        return PartnerUserRoleControllerApiFp(this.configuration).createOrUpdateUser(createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreatePartnerOwnerRequest} createPartnerOwnerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public createPartnerOwner(createPartnerOwnerRequest: CreatePartnerOwnerRequest, options?: AxiosRequestConfig) {
        return PartnerUserRoleControllerApiFp(this.configuration).createPartnerOwner(createPartnerOwnerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateRoleRequest} createRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public createRoleWithPermissions(createRoleRequest: CreateRoleRequest, options?: AxiosRequestConfig) {
        return PartnerUserRoleControllerApiFp(this.configuration).createRoleWithPermissions(createRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fusionAuthRef 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public deactivatePartnerUser(fusionAuthRef: string, options?: AxiosRequestConfig) {
        return PartnerUserRoleControllerApiFp(this.configuration).deactivatePartnerUser(fusionAuthRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public getPartnerUserSessionInfo(options?: AxiosRequestConfig) {
        return PartnerUserRoleControllerApiFp(this.configuration).getPartnerUserSessionInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fusionAuthRef 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public getPortalUserDetail(fusionAuthRef: string, options?: AxiosRequestConfig) {
        return PartnerUserRoleControllerApiFp(this.configuration).getPortalUserDetail(fusionAuthRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchParameters} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public getPortalUsers(searchParameters: SearchParameters, options?: AxiosRequestConfig) {
        return PartnerUserRoleControllerApiFp(this.configuration).getPortalUsers(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public getRoleList(options?: AxiosRequestConfig) {
        return PartnerUserRoleControllerApiFp(this.configuration).getRoleList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fusionAuthRef 
     * @param {UpdateUserRequest} updateUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public modifyUserRoles(fusionAuthRef: string, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig) {
        return PartnerUserRoleControllerApiFp(this.configuration).modifyUserRoles(fusionAuthRef, updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fusionAuthRef 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public reactivatePartnerUser(fusionAuthRef: string, options?: AxiosRequestConfig) {
        return PartnerUserRoleControllerApiFp(this.configuration).reactivatePartnerUser(fusionAuthRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InviteUserRequest} inviteUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public sendInvite(inviteUserRequest: InviteUserRequest, options?: AxiosRequestConfig) {
        return PartnerUserRoleControllerApiFp(this.configuration).sendInvite(inviteUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateRoleRequest} updateRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public updateRole(updateRoleRequest: UpdateRoleRequest, options?: AxiosRequestConfig) {
        return PartnerUserRoleControllerApiFp(this.configuration).updateRole(updateRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fusionAuthRef 
     * @param {UpdateUserPartnerRequest} updateUserPartnerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public updateUserPartner(fusionAuthRef: string, updateUserPartnerRequest: UpdateUserPartnerRequest, options?: AxiosRequestConfig) {
        return PartnerUserRoleControllerApiFp(this.configuration).updateUserPartner(fusionAuthRef, updateUserPartnerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartyControllerApi - axios parameter creator
 * @export
 */
export const PartyControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} partyId 
         * @param {ApiPartyDetailsRequest} apiPartyDetailsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartyDetails: async (partyId: string, apiPartyDetailsRequest: ApiPartyDetailsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('fetchPartyDetails', 'partyId', partyId)
            // verify required parameter 'apiPartyDetailsRequest' is not null or undefined
            assertParamExists('fetchPartyDetails', 'apiPartyDetailsRequest', apiPartyDetailsRequest)
            const localVarPath = `/partner-portal/party/{partyId}/details`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartyDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partyId 
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrivePortfolio: async (partyId: string, partnerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('retrivePortfolio', 'partyId', partyId)
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('retrivePortfolio', 'partnerId', partnerId)
            const localVarPath = `/partner-portal/party/{partyId}/partner/{partnerId}/portfolio`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)))
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsumers: async (searchParameters: SearchParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('searchConsumers', 'searchParameters', searchParameters)
            const localVarPath = `/partner-portal/party/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyId: async (inlineObject1: InlineObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject1' is not null or undefined
            assertParamExists('searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyId', 'inlineObject1', inlineObject1)
            const localVarPath = `/partner-portal/party/dashboard/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchParty: async (partyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('searchParty', 'partyId', partyId)
            const localVarPath = `/partner-portal/party/{partyId}`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartyControllerApi - functional programming interface
 * @export
 */
export const PartyControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartyControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} partyId 
         * @param {ApiPartyDetailsRequest} apiPartyDetailsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPartyDetails(partyId: string, apiPartyDetailsRequest: ApiPartyDetailsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiPartyDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPartyDetails(partyId, apiPartyDetailsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} partyId 
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrivePortfolio(partyId: string, partnerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiPartyPortfolio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrivePortfolio(partyId, partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchConsumers(searchParameters: SearchParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiParty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchConsumers(searchParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyId(inlineObject1: InlineObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiPartyBaseSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyId(inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} partyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchParty(partyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiParty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchParty(partyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartyControllerApi - factory interface
 * @export
 */
export const PartyControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartyControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} partyId 
         * @param {ApiPartyDetailsRequest} apiPartyDetailsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartyDetails(partyId: string, apiPartyDetailsRequest: ApiPartyDetailsRequest, options?: any): AxiosPromise<ApiResponseApiPartyDetails> {
            return localVarFp.fetchPartyDetails(partyId, apiPartyDetailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} partyId 
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrivePortfolio(partyId: string, partnerId: string, options?: any): AxiosPromise<ApiResponseApiPartyPortfolio> {
            return localVarFp.retrivePortfolio(partyId, partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsumers(searchParameters: SearchParameters, options?: any): AxiosPromise<ApiResponsePageApiParty> {
            return localVarFp.searchConsumers(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyId(inlineObject1: InlineObject1, options?: any): AxiosPromise<ApiResponsePageApiPartyBaseSearchResponse> {
            return localVarFp.searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyId(inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} partyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchParty(partyId: string, options?: any): AxiosPromise<ApiResponseApiParty> {
            return localVarFp.searchParty(partyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartyControllerApi - interface
 * @export
 * @interface PartyControllerApi
 */
export interface PartyControllerApiInterface {
    /**
     * 
     * @param {string} partyId 
     * @param {ApiPartyDetailsRequest} apiPartyDetailsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyControllerApiInterface
     */
    fetchPartyDetails(partyId: string, apiPartyDetailsRequest: ApiPartyDetailsRequest, options?: AxiosRequestConfig): AxiosPromise<ApiResponseApiPartyDetails>;

    /**
     * 
     * @param {string} partyId 
     * @param {string} partnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyControllerApiInterface
     */
    retrivePortfolio(partyId: string, partnerId: string, options?: AxiosRequestConfig): AxiosPromise<ApiResponseApiPartyPortfolio>;

    /**
     * 
     * @param {SearchParameters} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyControllerApiInterface
     */
    searchConsumers(searchParameters: SearchParameters, options?: AxiosRequestConfig): AxiosPromise<ApiResponsePageApiParty>;

    /**
     * 
     * @param {InlineObject1} inlineObject1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyControllerApiInterface
     */
    searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyId(inlineObject1: InlineObject1, options?: AxiosRequestConfig): AxiosPromise<ApiResponsePageApiPartyBaseSearchResponse>;

    /**
     * 
     * @param {string} partyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyControllerApiInterface
     */
    searchParty(partyId: string, options?: AxiosRequestConfig): AxiosPromise<ApiResponseApiParty>;

}

/**
 * PartyControllerApi - object-oriented interface
 * @export
 * @class PartyControllerApi
 * @extends {BaseAPI}
 */
export class PartyControllerApi extends BaseAPI implements PartyControllerApiInterface {
    /**
     * 
     * @param {string} partyId 
     * @param {ApiPartyDetailsRequest} apiPartyDetailsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyControllerApi
     */
    public fetchPartyDetails(partyId: string, apiPartyDetailsRequest: ApiPartyDetailsRequest, options?: AxiosRequestConfig) {
        return PartyControllerApiFp(this.configuration).fetchPartyDetails(partyId, apiPartyDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} partyId 
     * @param {string} partnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyControllerApi
     */
    public retrivePortfolio(partyId: string, partnerId: string, options?: AxiosRequestConfig) {
        return PartyControllerApiFp(this.configuration).retrivePortfolio(partyId, partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchParameters} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyControllerApi
     */
    public searchConsumers(searchParameters: SearchParameters, options?: AxiosRequestConfig) {
        return PartyControllerApiFp(this.configuration).searchConsumers(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject1} inlineObject1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyControllerApi
     */
    public searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyId(inlineObject1: InlineObject1, options?: AxiosRequestConfig) {
        return PartyControllerApiFp(this.configuration).searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyId(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} partyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyControllerApi
     */
    public searchParty(partyId: string, options?: AxiosRequestConfig) {
        return PartyControllerApiFp(this.configuration).searchParty(partyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SlaReportControllerApi - axios parameter creator
 * @export
 */
export const SlaReportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSLAReport: async (searchParameters: SearchParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('fetchSLAReport', 'searchParameters', searchParameters)
            const localVarPath = `/partner-portal/sla/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SlaReportControllerApi - functional programming interface
 * @export
 */
export const SlaReportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SlaReportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchSLAReport(searchParameters: SearchParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiSLAReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchSLAReport(searchParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SlaReportControllerApi - factory interface
 * @export
 */
export const SlaReportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SlaReportControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSLAReport(searchParameters: SearchParameters, options?: any): AxiosPromise<ApiResponsePageApiSLAReport> {
            return localVarFp.fetchSLAReport(searchParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SlaReportControllerApi - interface
 * @export
 * @interface SlaReportControllerApi
 */
export interface SlaReportControllerApiInterface {
    /**
     * 
     * @param {SearchParameters} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlaReportControllerApiInterface
     */
    fetchSLAReport(searchParameters: SearchParameters, options?: AxiosRequestConfig): AxiosPromise<ApiResponsePageApiSLAReport>;

}

/**
 * SlaReportControllerApi - object-oriented interface
 * @export
 * @class SlaReportControllerApi
 * @extends {BaseAPI}
 */
export class SlaReportControllerApi extends BaseAPI implements SlaReportControllerApiInterface {
    /**
     * 
     * @param {SearchParameters} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlaReportControllerApi
     */
    public fetchSLAReport(searchParameters: SearchParameters, options?: AxiosRequestConfig) {
        return SlaReportControllerApiFp(this.configuration).fetchSLAReport(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }
}


