import * as api from '@bakkt/api'
import {PartnerRolePermissionEnum, PortalRole, User} from './AppState'
export {PartnerRolePermissionEnum, PortalRole}

export const PARTNER_PORTAL_PERMISSIONS_LIST = Object.entries(api.ApiPortalRolePermissionsEnum).map(e => e[1])

class PermissionManager {
	currentUser: User | null = null

	/*
    returns boolean whether user has ANY of the required permissions
    @param userPermissions - array containing the user's permissions
    @param requiredPermissions - array containing permissions that are allowed to access
    */
	canAccess(userPermissions: PartnerRolePermissionEnum[], requiredPermissions: PartnerRolePermissionEnum[]) {
		if (userPermissions.find(userPermission => requiredPermissions.indexOf(userPermission) >= 0) != null)
			return true
		return false
	}

	/*
    state-dependent wrapper around canAccess that uses the user currently in the store
    returns boolean whether the operation is allowed for the user
    @param requiredPermissions - any allowed permissions
    */
	userAllowed(requiredPermissions: PartnerRolePermissionEnum[]) {
		const user = this.getCurrentUser()
		if (user == null) return false
		return this.canAccess(user.permissions, requiredPermissions)
	}

	getCurrentUserPermissions() {
		return this.currentUser && this.currentUser.permissions
	}

	getCurrentUser() {
		return this.currentUser
	}

	getAllPartnersObjCurrentUserCanAccess(): {[key: string]: string} {
		return this.getCurrentUser()?.partners
	}
	getAllPartnersArrayCurrentUserCanAccess(): {id: string; name: string}[] {
		const partners = this.getCurrentUser()?.partners
		const partnersArr: {id: string; name: string}[] = []

		for (const [id, name] of Object.entries(partners)) {
			partnersArr.push({id, name})
		}
		return partnersArr
	}

	setCurrentUser(user: User) {
		this.currentUser = user
	}

	isBakktUser = () => {
		const user = this.getCurrentUser()
		return user.email.endsWith('bakkt.com')
	}

	// Returns boolean whether user has ANY of the required CSR permissions
	isAnyCSRAdminPermission() {
		return this.userAllowed(Object.values(PartnerRolePermissionEnum))
	}

	canAcceptDispute() {
		return this.userAllowed([PartnerRolePermissionEnum.DISPUTE_ACCEPT])
	}

	canRejectDispute() {
		return this.userAllowed([PartnerRolePermissionEnum.DISPUTE_REJECT])
	}

	canAddComment() {
		return this.userAllowed([PartnerRolePermissionEnum.DISPUTE_ADD_COMMENT])
	}

	canTakeDisputeAction() {
		return this.userAllowed([
			PartnerRolePermissionEnum.DISPUTE_ACCEPT,
			PartnerRolePermissionEnum.DISPUTE_REJECT,
			PartnerRolePermissionEnum.DISPUTE_ADD_COMMENT,
		])
	}

	canCancelTransaction() {
		return this.userAllowed([PartnerRolePermissionEnum.TRANSACTION_CANCEL])
	}

	canReverseTransaction() {
		return this.userAllowed([PartnerRolePermissionEnum.TRANSACTION_REVERSE])
	}

	canTakeTransactionAction() {
		return this.userAllowed([
			PartnerRolePermissionEnum.TRANSACTION_CANCEL,
			PartnerRolePermissionEnum.TRANSACTION_REVERSE,
		])
	}

	canAddUser() {
		return this.userAllowed([PartnerRolePermissionEnum.USERS_CREATE])
	}

	canUpdateUser() {
		return this.userAllowed([PartnerRolePermissionEnum.USERS_UPDATE])
	}
	canCreateAndUpdateRole() {
		return this.userAllowed([api.ApiPortalRolePermissionsEnum.ROLE_CREATE_UPDATE])
	}
	canViewIdDocSubmission() {
		return this.userAllowed([api.ApiPortalRolePermissionsEnum.ID_DOC_SUBMISSION_VIEW])
	}
	isSupportedOperation(operation: api.ApiPartnerUserInfoSupportedOperationsEnum) {
		const user = this.getCurrentUser()
		return user.supportedOperations?.includes(operation)
	}
}

export default new PermissionManager()
