import React from 'react'
import {useSelector} from 'react-redux'
import {selectActiveRequests} from '../reducer'
import {Icons, color} from '@bakkt/components'

export default () => {
	const activeFetches = useSelector(selectActiveRequests)
	if (activeFetches <= 0) return null
	return (
		<div
			className='fade-in-loader'
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				zIndex: 9999,
				background: color.grey20,
				opacity: 0.85,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Icons.Loading style={{height: 168, width: 168}} />
		</div>
	)
}
