import {Utils, Select, VBox} from '@bakkt/components'
import {useEffect, useState} from 'react'

export async function showPartnerSelectDialog(errorMessage: string, partnerIds: string[] | undefined | null) {
	await Utils.showFuncDialog(errorMessage, SelectionTemplate, {
		partnerIds,
		hideSave: true,
		cancelText: 'OK',
	})
	window.location.reload()
	Utils.hideDialog()
}
interface IProps {
	partnerIds: string[] | undefined | null
}
export const SelectionTemplate = (props: IProps) => {
	const [selectPD, setSelectPD] = useState(null)
	useEffect(() => {
		if (props.partnerIds.length) {
			handleSelectPartnerId(props.partnerIds[0])
		}
	}, [props.partnerIds])

	const handleSelectPartnerId = (parterId: string) => {
		setSelectPD(parterId)
		localStorage.setItem('selectedPartnerId', parterId)
		window.history.replaceState(null, null, '?selectedPartnerId=' + parterId)
	}

	return (
		<VBox verticalGap={10}>
			{props.partnerIds && (
				<Select
					label='Select partner id'
					value={selectPD}
					containerStyle={{width: '100%'}}
					options={props.partnerIds}
					onChange={(e, v) => {
						handleSelectPartnerId(v)
					}}
					disableClearable={true}
					disableSearchable={true}
				></Select>
			)}
		</VBox>
	)
}
