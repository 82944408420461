import PermissionManager, {PartnerRolePermissionEnum} from './app/PermissionManager'

export enum WIDGET_ID {
	MERCHANT_TXNS = 'm_txns',
	MERCHANT_TXN_DETAIL = 'm_transactionDetail',
	MERCHANT_DISPUTES = 'm_disputes',
	MERCHANT_SETTLEMENT_REPORTS = 'm_settlement_reports',
	MERCHANT_USERS = 'm_users',
	MERCHANT_MERCHANT_PROFILE = 'm_Merchant_Profile',
	PARTNER_DASHBOARD = 'p_dashboard',
	PARTNER_PROFILE = 'p_profile',
	PARTNER_TXNS = 'p_txns',
	PARTNER_TXN_DETAIL = 'p_transactionDetail',
	PARTNER_DISPUTES = 'p_disputes',
	PARTNER_SETTLEMENT_REPORTS = 'p_settlement_reports',
	PARTNER_USERS = 'p_users',
	PARTNER_MERCHANT_PROFILE = 'p_Merchant_Profile',
	TXN_SEARCH_LIST = 'p_txn_search',
	PARTY_SEARCH_LIST = 'p_party_search',
	PARTNER_CONSUMER_DETAIL = 'p_consumer_detail',
	ROLE_MANAGEMENT = 'p_role_management',
	PARTNER_SLA_REPORTS = 'p_sla_reports',
}

export default {
	widgets: [
		{
			id: WIDGET_ID.PARTNER_DASHBOARD,
			name: 'Dashboard',
			component: () => import('./partner/dashboard/DashboardView'),
			isVisibleInWidgetMenu: false,
			isPermissoned: () => {
				return PermissionManager.userAllowed([PartnerRolePermissionEnum.USERS_VIEW])
			},
			required: true,
		},
		{
			id: WIDGET_ID.PARTNER_TXNS,
			name: 'Transactions',
			component: () => import('./partner/transactions/TransactionsView'),
			isVisibleInWidgetMenu: false,
			isPermissoned: () => {
				return PermissionManager.userAllowed([PartnerRolePermissionEnum.TRANSACTION_VIEW])
			},
			required: true,
		},
		{
			id: WIDGET_ID.PARTNER_DISPUTES,
			name: 'Disputes',
			component: () => import('./partner/disputes/DisputesView'),
			isVisibleInWidgetMenu: false,
			isPermissoned: () => {
				return PermissionManager.userAllowed([PartnerRolePermissionEnum.DISPUTE_VIEW])
			},
			required: true,
		},
		{
			id: WIDGET_ID.PARTNER_SETTLEMENT_REPORTS,
			name: 'Settlement Reports',
			component: () => import('./partner/settlementReports/SettlementReportView'),
			isVisibleInWidgetMenu: false,
			isPermissoned: () => {
				return PermissionManager.userAllowed([PartnerRolePermissionEnum.SETTLEMENT_REPORT_VIEW])
			},
			required: true,
		},
		{
			id: WIDGET_ID.PARTNER_SLA_REPORTS,
			name: 'Sla Reports',
			component: () => import('./partner/slaReports/SlaReportView'),
			isVisibleInWidgetMenu: false,
			isPermissoned: () => {
				return PermissionManager.userAllowed([PartnerRolePermissionEnum.SLA_REPORT_VIEW])
			},
			required: true,
		},
		{
			id: WIDGET_ID.PARTNER_PROFILE,
			name: 'Partner Profile',
			component: () => import('./partner/users/PartnerProfile'),
			isVisibleInWidgetMenu: false,
			isPermissoned: () => {
				return PermissionManager.userAllowed([PartnerRolePermissionEnum.USERS_VIEW])
			},
			required: true,
		},
		{
			id: WIDGET_ID.PARTNER_USERS,
			name: 'Users',
			component: () => import('./partner/users/UsersListView'),
			isVisibleInWidgetMenu: false,
			isPermissoned: () => {
				return PermissionManager.userAllowed([PartnerRolePermissionEnum.USERS_VIEW])
			},
		},
		{
			id: WIDGET_ID.ROLE_MANAGEMENT,
			name: 'Role Management',
			component: () => import('./partner/users/RoleListView'),
			isVisibleInWidgetMenu: false,
			isPermissoned: () => {
				return PermissionManager.userAllowed([PartnerRolePermissionEnum.ROLE_CREATE_UPDATE])
			},
		},
		{
			id: WIDGET_ID.PARTNER_TXN_DETAIL,
			name: 'Transaction Details',
			component: () => import('./partner/transactions/TransactionDetails'),
			isVisibleInWidgetMenu: false,
			isPermissoned: () => {
				return PermissionManager.userAllowed([PartnerRolePermissionEnum.TRANSACTION_VIEW])
			},
		},
		{
			id: WIDGET_ID.TXN_SEARCH_LIST,
			name: 'Trasnsaction List',
			component: () => import('./partner/dashboard/TransactionSearchListView'),
			isVisibleInWidgetMenu: false,
			isPermissoned: () => {
				return PermissionManager.userAllowed([PartnerRolePermissionEnum.TRANSACTION_VIEW])
			},
		},
		{
			id: WIDGET_ID.PARTY_SEARCH_LIST,
			name: 'Consumer Search List',
			component: () => import('./partner/dashboard/PartySearchListView'),
			isVisibleInWidgetMenu: false,
			isPermissoned: () => {
				return PermissionManager.userAllowed([PartnerRolePermissionEnum.USERS_VIEW])
			},
		},
		{
			id: WIDGET_ID.PARTNER_CONSUMER_DETAIL,
			name: 'Consumer Details',
			component: () => import('./partner/party/index'),
			isVisibleInWidgetMenu: false,
			isPermissoned: () => {
				return PermissionManager.userAllowed([PartnerRolePermissionEnum.USERS_VIEW])
			},
		},
	],
}
